import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      isBusy: {},
    };
  },
});

export function asyncAction(id: string, func: Function) {
  return function (this: any) {
    if (this.isBusy[id]) {
      return Promise.reject(
        new BusyError(`Action with id '${id}' is still pending`)
      );
    }

    this.$set(this.isBusy, id, true);

    return Promise.resolve(func.apply(this, arguments)).finally(() => {
      this.$set(this.isBusy, id, false);
    });
  };
}

class BusyError extends Error {
  constructor(message: string) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BusyError);
    }
  }
}
