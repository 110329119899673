export const DATE_FORMAT_OPTIONS = [
  { name: '30-01-2020 (DD-MM-YYYY)', value: 'DD-MM-YYYY' },
  { name: '01/30/2020 (MM/DD/YYYY)', value: 'MM/DD/YYYY' },
  { name: '30.01.2020 (DD.MM.YYYY)', value: 'DD.MM.YYYY' },
  { name: '2020-01-30 (YYYY-MM-DD)', value: 'YYYY-MM-DD' },
];
export const TIME_FORMAT_OPTIONS = [
  { name: '13:00 (24 hours)', value: '24h' },
  { name: '1:00 PM (12 hours)', value: '12h' },
];

export function getDateFormat(defaultFormat: string): string {
  if (defaultFormat) {
    return defaultFormat;
  } else {
    return DATE_FORMAT_OPTIONS[0].value;
  }
}

export function getTimeFormat(defaultFormat: string): string {
  if (defaultFormat) {
    return defaultFormat;
  } else {
    return TIME_FORMAT_OPTIONS[0].value;
  }
}
