<template>
  <form
    class="compose panel"
    @submit.prevent
    v-test:composeForm
    novalidate
    @dragenter="maybeShowExtraFields"
    @dragover="maybeShowExtraFields"
  >
    <Modal
      :modal-is-open="isLeavingWhileNotSaved"
      @modalToggled="leaveWhileNotSavedCallback(false)"
    >
      <template v-slot:header>
        <translate>Discard unsaved changes?</translate>
      </template>
      <template v-slot:content="modal">
        <translate
          >Your draft contains unsaved changes, these will be lost if you
          leave.</translate
        >
        <ModalConfirmActions
          :ok-text="leaveText"
          @okClicked="leaveWhileNotSavedCallback(true)"
          @cancelClicked="modal.toggle"
        />
      </template>
    </Modal>

    <Modal
      :modal-is-open="isLeavingWhileUpdatingAttachment"
      @modalToggled="leaveWhileUploadingCallback(false)"
    >
      <template v-slot:header>
        <translate>Leave message while uploading?</translate>
      </template>
      <template v-slot:content="modal">
        <translate
          >Message attachments will not be saved if you leave while uploading or
          deleting.</translate
        >
        <ModalConfirmActions
          :ok-text="leaveText"
          @okClicked="leaveWhileUploadingCallback(true)"
          @cancelClicked="modal.toggle"
        />
      </template>
    </Modal>

    <CompleteDomainModal
      @modalToggled="showCompleteDomainModal = !showCompleteDomainModal"
      :is-open="showCompleteDomainModal"
      :title="modalTitleText"
      :paragraph="modalParagraphText"
    />

    <div
      class="draft-last-saved"
      v-if="lastSaved"
      :key="lastSaved"
      v-test:draft-last-saved
    >
      <Spinner class="draft-last-saved__spinner" v-if="savingDraft" />
      <translate>Last saved</translate>: {{ lastSaved }}
    </div>
    <div class="compose-form__primary-info panel__section">
      <fieldset class="compose__form-inputs">
        <div
          class="compose__form-inputs__from"
          :class="{
            'compose__form-inputs__from--invalid': !selectedAlias,
          }"
        >
          <label for="from" class="compose__form-inputs__from-label">
            <strong>{{ fromText }}:</strong>
          </label>
          <ComposeSelectInput
            id="from"
            v-test:from
            :label="fromText"
            :options="fromOptions"
            v-model="selectedAlias"
            @focus="onExtraFieldsFocus"
            required
            :validate="aliasFromIncompleteDomain || !selectedAlias"
            :show-custom-validity="aliasFromIncompleteDomain"
            :validation-error-message="validationErrorMessage"
          >
            <template v-slot:button>
              <router-link
                v-if="aliasFromIncompleteDomain"
                :to="domainsSettingsRoute"
                type="button"
                class="button button--small compose-form__input-button compose-form__input-button--no-margin"
              >
                <Icon symbol="gear" class="compose-form__input-button-icon" />
                <span class="compose-form__input-button-label" v-translate>
                  Complete your domain setup
                </span>
              </router-link>
            </template>
          </ComposeSelectInput>
          <CustomAliasModal
            class="compose__form-inputs__from__create-alias-modal"
            type="personal"
            @created="selectedAlias = $event.alias"
          >
            <template v-slot:toggle="{ toggle }">
              <Tutorial
                :tutorialStep="tutorialStep"
                :tutorialStepTotal="tutorialStepTotal"
                :isCurrentStep="tutorialStep === 1"
                :specialClass="'create-alias'"
                @next="incrementTutorialStep"
              >
                <button
                  class="button button--small compose-form__input-button"
                  @click="toggle"
                  type="button"
                  v-test:createAlias
                >
                  <strong><translate>Create alias</translate></strong>
                </button>
                <template v-slot:popper>
                  <translate>
                    Don't want the recipient to know your main email address?
                    Create an alias to send from.
                  </translate>
                </template>
              </Tutorial>
            </template>
          </CustomAliasModal>
        </div>

        <ComposeInputField
          v-test:to
          id="to"
          ref="to"
          :label="toText"
          v-model="form.to"
          :show-key-availability="form.encrypted"
          :start-focussed="!focusBody"
          :validation-error-message="invalidRecipientText"
          validate
          :invalid-email-addresses="invalidToEmailAddresses"
          :key-state="keyState"
          :truncate="truncateRecipientListTo"
          :truncate-max-items="truncateRecipientListMaxItems"
          :hide-input-field="showMoreToRecipientsButton"
          @focusout.native="onToFieldFocusOut"
        >
          <template
            v-slot:button
            v-if="showMoreToRecipientsButton || !shouldShowExtraFields"
          >
            <button
              v-if="showMoreToRecipientsButton"
              :id="MORE_TO_BUTTON_ID"
              class="button button--link button--more"
              type="button"
              @click="showMoreToRecipients"
            >
              {{ moreRecipientsButtonText('to') }}
            </button>

            <button
              v-if="!shouldShowExtraFields"
              type="button"
              class="button button--small compose-form__input-button compose-form__cc-button"
              :title="ccBccText"
              v-test:extraFieldsButton
              @click="onExtraFieldsButtonClick"
            >
              <strong>{{ ccBccText }}</strong>
            </button>
          </template>
        </ComposeInputField>

        <transition name="collapsible">
          <div v-show="shouldShowExtraFields" v-test:extraFields>
            <ComposeInputField
              id="cc"
              class="compose__-form__inputs"
              ref="cc"
              :label="ccText"
              v-model="form.cc"
              v-test:cc
              :validation-error-message="invalidRecipientText"
              validate
              :show-key-availability="form.encrypted"
              :invalid-email-addresses="invalidCcEmailAddresses"
              :key-state="keyState"
              :truncate="truncateRecipientListCc"
              :truncate-max-items="truncateRecipientListMaxItems"
              @focus="onExtraFieldsFocus"
              :hide-input-field="showMoreCcRecipientsButton"
              @focusout.native="onCcFieldFocusOut"
            >
              <template v-slot:button v-if="showMoreCcRecipientsButton">
                <button
                  :id="MORE_CC_BUTTON_ID"
                  class="button button--link button--more"
                  type="button"
                  @click.stop="showMoreCcRecipients"
                >
                  {{ moreRecipientsButtonText('cc') }}
                </button>
              </template>
            </ComposeInputField>

            <ComposeInputField
              id="bcc"
              ref="bcc"
              :label="bccText"
              v-model="form.bcc"
              v-test:bcc
              :validation-error-message="invalidRecipientText"
              validate
              :show-key-availability="form.encrypted"
              :invalid-email-addresses="invalidBccEmailAddresses"
              :hide-input-field="showMoreBccRecipientsButton"
              class="compose__form-inputs__bcc"
              :key-state="keyState"
              :truncate="truncateRecipientListBcc"
              :truncate-max-items="truncateRecipientListMaxItems"
              @focus="onExtraFieldsFocus"
              @focusout.native="onBccFieldFocusOut"
            >
              <template v-slot:button v-if="showMoreBccRecipientsButton">
                <button
                  :id="MORE_BCC_BUTTON_ID"
                  class="button button--link button--more"
                  type="button"
                  @click="showMoreBccRecipients"
                >
                  {{ moreRecipientsButtonText('bcc') }}
                </button>
              </template>
            </ComposeInputField>
          </div>
        </transition>

        <div class="compose-form-subject-field">
          <label for="subject" class="compose-form-subject-field__label"
            ><strong>{{ subjectText }}: </strong></label
          >
          <input
            id="subject"
            type="text"
            v-model="form.subject"
            class="compose-form-subject-field__input"
            v-test:subject
          />
        </div>
      </fieldset>
      <transition name="collapsible">
        <AttachmentList
          v-if="attachments.length || form.attach_public_key"
          id="attachments"
          :attachments="attachments"
          :show-public-key="form.attach_public_key"
          @change="onAttachmentsChanged"
          @attachmentRemoved="onAttachmentRemoved"
          @removePublicKey="removePublicKey"
        />
      </transition>
    </div>

    <transition name="collapsible">
      <div
        v-if="showMoveToBccWarning && !dismissWarning"
        class="move-to-bcc-warning"
        v-test:showMoveToBccWarning
      >
        <div class="move-to-bcc-warning-text row">
          <Icon symbol="tip" />
          <p v-translate v-test:showMoveToBccWarningParagraph>
            Privacy tip: keep the recipients’ email address hidden from others
            by moving them to Bcc. This notification appears with more than 25
            recipients (excluding Bcc).
            <router-link :to="mailSettingsRoute"
              >Change this setting</router-link
            >
          </p>
        </div>
        <div class="move-to-bcc-warning-buttons button-group">
          <button
            type="button"
            class="button button--accent"
            @click="dismissWarning = true"
          >
            <translate>Dismiss</translate>
          </button>
          <button
            type="button"
            class="button button--primary"
            @click="moveToBccField"
          >
            <translate>Move to Bcc</translate>
          </button>
        </div>
      </div>
    </transition>
    <RichTextEditor
      ref="editor"
      class="compose__editor panel__section"
      :class="{
        'dark-mode-reset': darkModeResetEditor && darkMode,
      }"
      :value="form.body"
      :focused="focusBody"
      :inline-images-enabled="true"
      :editor-preferences="editorPreferences"
      :inline-attachments="inlineAttachments"
      :previous-element-focused="previousElementFocused"
      @change="onBodyChanged"
      @dropFiles="onAttachmentsChanged"
      @inlineImageInserted="onInlineImageInserted"
      @inlineImageRemoved="onAttachmentRemoved"
      :total-attachments-size="totalAttachmentsSize"
    >
      <template v-slot:draftActions>
        <div
          class="rich-text-editor__format-bar-group rich-text-editor__draft-actions"
        >
          <Tutorial
            :tutorialStep="tutorialStep"
            :tutorialStepTotal="tutorialStepTotal"
            :isCurrentStep="tutorialStep === 3"
            :specialClass="'compose-attach'"
            @next="incrementTutorialStep"
          >
            <Dropdown class="attachment-button dropdown--align-left">
              <template v-slot:button="{ isOpen, toggle }">
                <ActionBarButton
                  :dropdown="true"
                  :active="isOpen"
                  @click="toggle"
                  class="rich-text-editor__format-bar-button"
                  icon="attach"
                  :aria-label="attachText"
                  v-tooltip="attachText"
                />
              </template>
              <template v-slot:content="{ toggle }">
                <ul class="link-list" @click="toggle">
                  <li>
                    <input
                      v-test:file
                      type="file"
                      ref="file"
                      aria-hidden="true"
                      class="visually-hidden"
                      multiple
                      @change="onAttachmentsChanged($event.target.files)"
                    />
                    <button
                      v-test:attachFileButton
                      :title="attachText"
                      @click.stop="$refs.file.click()"
                      type="button"
                      class="button button--clean link-list__item"
                    >
                      {{ attachFileText }}
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      class="button button--clean link-list__item"
                      :title="toggleAttachKeyText"
                      @click="toggleAttachKey"
                      :disabled="disablePgpEnhancements"
                    >
                      {{ attachPublicKeyText }}
                    </button>
                  </li>
                </ul>
              </template>
            </Dropdown>
            <template v-slot:popper>
              <translate>
                If you want to use encryption with non-StartMail users you will
                need to exchange public keys with each other. Here you can
                access your key and attach it as a file to your email.
              </translate>
            </template>
          </Tutorial>
          <DeleteConfirmationModal
            @delete="discardDraft"
            :ok-button-text="discardDraftText"
            :warning-text="discardDraftWarningText"
            :async-action-pending="isBusy.discardingDraft"
            class="compose-form__discard-draft"
          >
            <template v-slot:DeleteButton="{ toggle }">
              <ActionBarButton
                :aria-label="discardDraftText"
                v-tooltip="{
                  placement: doubleComposerControlBar ? 'left' : 'bottom',
                  content: discardDraftText,
                }"
                icon="trash"
                @click="toggle"
                v-test:discardDraft
                class="rich-text-editor__format-bar-button"
              />
            </template>
          </DeleteConfirmationModal>
        </div>
      </template>
      <template v-slot:externalActions>
        <div class="row">
          <div
            v-if="darkMode"
            class="compose-form__toggle-wrapper row"
            :class="{
              'compose-form__toggle-wrapper--active': darkModeResetEditor,
            }"
          >
            <FlipSwitch
              id="dark-mode-reset"
              :label="darkModeResetEditorText"
              class="compose-form__toggle"
              :checked="darkModeResetEditor"
              @change="setDarkModeEditorReset"
            />
            <Icon symbol="moon" class="compose-form__toggle-icon" />
          </div>
          <Tutorial
            :tutorialStep="tutorialStep"
            :tutorialStepTotal="tutorialStepTotal"
            :isCurrentStep="tutorialStep === 4"
            :specialClass="'compose-sign'"
            @next="incrementTutorialStep"
          >
            <div
              class="compose-form__toggle-wrapper row"
              :class="{
                'compose-form__toggle-wrapper--active': form.signed,
                'compose-form__toggle-wrapper--disabled':
                  disablePgpEnhancements,
              }"
            >
              <FlipSwitch
                id="sign"
                :label="signText"
                v-model="form.signed"
                class="compose-form__toggle"
                :disabled="disablePgpEnhancements"
              />
              <Icon symbol="signed" class="compose-form__toggle-icon" />
            </div>
            <template v-slot:popper>
              <translate>
                Sign your email and prove to the recipients that your message is
                authentic.
              </translate>
            </template>
          </Tutorial>
          <Tutorial
            :tutorialStep="tutorialStep"
            :tutorialStepTotal="tutorialStepTotal"
            :isCurrentStep="tutorialStep === 2"
            :specialClass="'compose-encrypt'"
            @next="incrementTutorialStep"
          >
            <div
              class="compose-form__toggle-wrapper row"
              :class="{
                'compose-form__toggle-wrapper--active': form.encrypted,
                'compose-form__toggle-wrapper--disabled':
                  disablePgpEnhancements,
              }"
            >
              <FlipSwitch
                id="encrypt"
                :label="encryptText"
                v-model="isEncrypted"
                class="compose-form__toggle"
                :disabled="disablePgpEnhancements"
              />
              <Icon
                symbol="locked"
                :key="form.encrypted"
                class="compose-form__toggle-icon"
              />
            </div>
            <template v-slot:popper>
              <translate>
                Encrypt your email and ensure that only the recipients can read
                your message.
              </translate>
            </template>
          </Tutorial>
        </div>
      </template>
    </RichTextEditor>
    <PPMForm
      :modal-is-open="showPasswordMessageModal"
      :key="`ppm-form-${showPasswordMessageModal ? 'open' : 'closed'}`"
      @cancelClicked="showPasswordMessageModal = false"
      @modalToggled="showPasswordMessageModal = false"
      :key-not-available-recipients="keyNotAvailableRecipients"
      :is-signed="form.signed"
      :sending="sending"
    />
    <SigningModal
      :show-signing-modal="showSigningModal"
      :decryption-bad-passphrase="decryptionBadPassphrase"
      @sendSignedMessage="sendSignedMessage"
      @modalToggled="resetPgpPassphraseModal"
      @resetDecryptionBadPassphrase="decryptionBadPassphrase = false"
    />
  </form>
</template>

<script>
  import Vue from 'vue';
  import { mapGetters, mapActions, mapState } from 'vuex';
  import debounce from '@/lib/debounce';
  import formatRecipient from '@/lib/formatRecipient';
  import { isValid } from '@/lib/mailAddress';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import confirmNavigationMixin from '@/mixins/confirm-navigation-mixin';
  import {
    MAIL_COMPOSE,
    MAIL_FOLDER,
    MAIL_SEARCH,
    SETTINGS_MAIL,
    SETTINGS_DOMAINS,
  } from '@/router/named-routes';
  import { SENDING_DRAFT } from '@/store/mail/mail-mutation-types';
  import getErrorCode from '@/api/get-error-code';
  import { ATTACHMENT_IS_TOO_BIG } from '@/api/error-codes';
  import CustomAliasModal from '@/components/CustomAliasModal';
  import ComposeSelectInput from '@/components/ComposeSelectInput/ComposeSelectInput';
  import ComposeInputField from '@/components/ComposeInputField/ComposeInputField';
  import Icon from '@/components/Icon/Icon';
  import ActionBarButton from '@/components/ActionBarButton/ActionBarButton';
  import Dropdown from '@/components/Dropdown/Dropdown';
  import FlipSwitch from '@/components/form/FlipSwitch';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions';
  import AttachmentList from '@/components/AttachmentList/AttachmentList';
  import Modal from '@/components/Modal';
  import CompleteDomainModal from '@/components/CompleteDomainModal/CompleteDomainModal.vue';
  import DeleteConfirmationModal from '@/components/DeleteConfirmationModal/DeleteConfirmationModal';
  import Spinner from '@/components/Spinner/Spinner';
  import PPMForm from '@/components/PPMForm/PPMForm';
  import SigningModal from '@/components/SigningModal/SigningModal';
  import {
    PGP_BAD_PASSPHRASE,
    PGP_NO_KEYS_AVAILABLE,
    PGP_KEY_NOT_AVAILABLE,
  } from '@/api/error-codes';
  import { isToday, mixin as timeMixin } from '@/lib/time';
  import { getDateFormat, getTimeFormat } from '@/lib/dateTimeFormats';
  import Tutorial from '@/components/Tutorial/Tutorial';

  const desktopMediaQuery = window.matchMedia('screen and (max-width: 1500px)');

  const MORE_BCC_BUTTON_ID = 'more-bcc-button';
  const MORE_CC_BUTTON_ID = 'more-cc-button';
  const MORE_TO_BUTTON_ID = 'more-to-button';

  const RichTextEditor = () => ({
    component: import(
      /* webpackChunkName: "rich-text-editor" */ '@/components/RichTextEditor/RichTextEditor'
    ),
    loading: Vue.component('rich-text-editor-loading', {
      components: { Spinner },
      render(h) {
        return h('div', [
          h('div', { class: ['rich-text-editor__editor'] }, [
            h('div', { class: ['panel', 'panel--inverse'] }, [
              h('div', { class: ['row', 'row--separated'] }, [
                h('span', { class: ['col', 'col--auto'] }, [h('Spinner')]),
                h('span', { class: ['col'] }, this.$gettext('Loading editor')),
              ]),
            ]),
          ]),
        ]);
      },
    }),
    error: Vue.component('rich-text-editor-error', {
      components: { Icon },
      render(h) {
        return h('div', [
          h('div', { class: ['rich-text-editor__editor'] }, [
            h('p', { class: ['panel', 'panel--inverse'] }, [
              h('div', { class: ['row', 'row--separated'] }, [
                h('span', { class: ['col', 'col--auto'] }, [
                  h('Icon', {
                    class: ['danger'],
                    props: { symbol: 'error' },
                  }),
                ]),
                h(
                  'span',
                  { class: ['col'] },
                  this.$gettext(
                    'The editor failed to load. Please reload to try again!'
                  )
                ),
              ]),
            ]),
            h(
              'button',
              {
                attrs: {
                  type: 'button',
                },
                class: ['button', 'button--primary'],
                on: {
                  click: () => window.location.reload(true),
                },
              },
              this.$gettext('Reload')
            ),
          ]),
        ]);
      },
    }),
    timeout: 10000,
  });
  const RECIPIENTS_WARNING = 25;

  export default {
    components: {
      ActionBarButton,
      CustomAliasModal,
      AttachmentList,
      ComposeInputField,
      CompleteDomainModal,
      DeleteConfirmationModal,
      Dropdown,
      FlipSwitch,
      Icon,
      Modal,
      ModalConfirmActions,
      PPMForm,
      RichTextEditor,
      ComposeSelectInput,
      SigningModal,
      Spinner,
      Tutorial,
    },
    mixins: [asyncActionsMixin, confirmNavigationMixin, timeMixin],
    props: {
      message: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      focusBody: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        // The form is only based on the message during creation, after that it is 1 way bound.
        // Do not create two way binding or it will result in an endless loop of draft saving.
        form: this.message,
        extraFieldsFocus: false,
        isLeavingWhileUpdatingAttachment: false,
        leaveWhileUploadingCallback: null,
        isLeavingWhileNotSaved: false,
        leaveWhileNotSavedCallback: null,
        savingDraft: false,
        showPasswordMessageModal: false,
        showCompleteDomainModal: false,
        version: 0,
        versionSaved: 0,
        decryptionBadPassphrase: false,
        showSigningModal: false,
        truncateRecipientListMaxItems: 10,
        truncateRecipientListTo: true,
        truncateRecipientListCc: true,
        truncateRecipientListBcc: true,
        passwordProtectedMessage: null,
        dismissWarning: false,
        doubleComposerControlBar: desktopMediaQuery.matches,
        MORE_TO_BUTTON_ID,
        MORE_CC_BUTTON_ID,
        MORE_BCC_BUTTON_ID,
        previousElementFocused: null,
        tutorialStep: 0,
        tutorialStepTotal: 4,
      };
    },
    computed: {
      ...mapState({
        keyState: (state) => state.drafts.keyState,
        editorPreferences: (state) =>
          state.authentication.user.preferences.editor,
        darkMode: (state) => state.settings.darkMode,
        darkModeResetEditor: (state) => state.settings.darkModeResetEditor,
        draft: (state) => state.drafts.draft,
      }),
      ...mapGetters([
        'activeFolder',
        'attachments',
        'isUpdatingAttachment',
        'allRecipientsDraft',
        'inlineAttachments',
        'totalAttachmentsSize',
        'keyNotAvailableRecipients',
        'keyCheckSuccessful',
        'sending',
      ]),
      ...mapGetters('authentication', ['user']),
      ...mapGetters('aliases', ['findSenderAlias', 'senderAliases']),
      invalidRecipientText() {
        return this.$gettext('One or more mail addresses are invalid');
      },
      validationErrorMessage() {
        return this.aliasFromIncompleteDomain
          ? this.$gettext('Domain setup not complete to send from this email.')
          : this.$gettext('Please select an email address.');
      },
      aliasFromIncompleteDomain() {
        if (!this.selectedAlias || !this.user.domains) {
          return false;
        }
        const domainName = this.selectedAlias.split('@')[1];
        return this.user.domains.some(
          (domain) => domain.domain_name === domainName && !domain.completed
        );
      },
      invalidToEmailAddresses() {
        return Array.from(
          new Set([
            ...this.invalidEmailAddresses(this.message.to),
            ...this.invalidEmailAddresses(this.form.to),
          ])
        );
      },
      invalidCcEmailAddresses() {
        return Array.from(
          new Set([
            ...this.invalidEmailAddresses(this.message.cc),
            ...this.invalidEmailAddresses(this.form.cc),
          ])
        );
      },
      invalidBccEmailAddresses() {
        return Array.from(
          new Set([
            ...this.invalidEmailAddresses(this.message.bcc),
            ...this.invalidEmailAddresses(this.form.bcc),
          ])
        );
      },
      isEncrypted: {
        get() {
          return this.form.encrypted;
        },
        set(value) {
          this.form.encrypted = value;
          this.$root.$emit('encryptionEnabledChanged', value);
        },
      },
      leaveText() {
        return this.$gettext('Leave');
      },
      ccText() {
        return this.$gettext('Cc');
      },
      bccText() {
        return this.$gettext('Bcc');
      },
      fromText() {
        return this.$gettext('From');
      },
      subjectText() {
        return this.$gettext('Subject');
      },
      toText() {
        return this.$gettext('To');
      },
      ccBccText() {
        return this.$gettext('Cc/Bcc');
      },
      domainsSettingsRoute() {
        return {
          name: SETTINGS_DOMAINS,
        };
      },
      toggleAttachKeyText() {
        return this.form.attach_public_key
          ? this.$gettext('Do not attach my public key to this email')
          : this.$gettext('Attach my public key to this email');
      },
      attachText() {
        return this.$gettext('Attach');
      },
      attachFileText() {
        return this.$gettext('Attach file');
      },
      encryptText() {
        return this.$gettext('Encrypt');
      },
      signText() {
        return this.$gettext('Sign');
      },
      darkModeResetEditorText() {
        return this.$gettext('Light editor');
      },
      attachPublicKeyText() {
        return this.$gettext('Attach public key');
      },
      discardDraftText() {
        return this.$gettext('Discard draft');
      },
      discardDraftWarningText() {
        return this.$gettext(
          'Your draft will be permanently deleted. It is not possible to undo this action.'
        );
      },
      fromOptions() {
        return this.senderAliases.map((alias) => ({
          name: formatRecipient({
            name: alias.displayName,
            email: alias.alias,
          }),
          value: alias.alias,
        }));
      },
      mailSettingsRoute() {
        return {
          name: SETTINGS_MAIL,
        };
      },
      showMoveToBccWarning() {
        return (
          this.user.preferences.recipient_count_warning &&
          this.form.to.length + this.form.cc.length >= RECIPIENTS_WARNING
        );
      },
      selectedAlias: {
        get() {
          return this.form.from?.email ?? '';
        },
        set(emailAddress) {
          this.form.from = {};
          const alias = this.findSenderAlias([emailAddress]);

          if (alias) {
            this.form.from.name = alias.displayName;
            this.form.from.email = alias.alias;
          }
          this.updateKeyState();
        },
      },
      shouldShowExtraFields() {
        const extraFieldsFilledIn = Boolean(
          this.form.cc.length || this.form.bcc.length
        );
        return Boolean(extraFieldsFilledIn || this.extraFieldsFocus);
      },
      showMoreToRecipientsButton() {
        return (
          this.form.to.length > this.truncateRecipientListMaxItems &&
          this.truncateRecipientListTo
        );
      },
      showMoreCcRecipientsButton() {
        return (
          this.form.cc.length > this.truncateRecipientListMaxItems &&
          this.truncateRecipientListCc
        );
      },
      showMoreBccRecipientsButton() {
        return (
          this.form.bcc.length > this.truncateRecipientListMaxItems &&
          this.truncateRecipientListBcc
        );
      },
      allRecipientsAddresses() {
        return [...this.form.to, ...this.form.cc, ...this.form.bcc].map(
          (recipient) => recipient.email
        );
      },
      disablePgpEnhancements() {
        return this.form.from?.email !== this.user.email;
      },
      lastSaved() {
        if (!this.draft.date) {
          return false;
        }
        const preferences = this.user && this.user.preferences;
        if (isToday(this.draft.date)) {
          const format = preferences && preferences.time_format;
          const timeFormat = getTimeFormat(format || '');
          const timeFormatString = timeFormat === '12h' ? 'h:mm A' : 'H:mm';
          return this.formatDate(this.draft.date, timeFormatString);
        } else {
          const user = this.user;
          const format =
            user && user.preferences && user.preferences.date_format;
          const dateFormat = getDateFormat(format || '');
          return this.formatDate(this.draft.date, dateFormat);
        }
      },
      modalTitleText() {
        return this.$gettext('Send email');
      },
      modalParagraphText() {
        return this.$gettext(
          'Before you can send and receive emails with your domain, you’ll need to complete your domain setup to work with StartMail.'
        );
      },
    },
    watch: {
      uploading(newValue) {
        // when upload is finished there is no need to show the "leave while uploading" dialog. So lets close it.
        if (!newValue && this.isLeavingWhileUpdatingAttachment) {
          this.isLeavingWhileUpdatingAttachment = false;
        }

        // this will enable the "unsaved data" dialog when leaving to a different url (outside the application). See the confirmNavigationMixin for more info.
        this.setDirty(newValue);
      },
      version() {
        this.setDirty(true);
      },
      versionSaved(savedVersion) {
        if (savedVersion === this.version) {
          this.setDirty(false);
        }
      },
      async allRecipientsAddresses(newAddresses, oldAddresses) {
        const { addresses, encrypt, sign } = await this.updateKeyState();
        if (encrypt || sign) {
          /* Check the recently added addresses for contact settings
          "always_encrypt" and "always_sign" and apply them to the draft */
          const recentlyAddedAddresses = newAddresses
            .filter((newAddress) => !oldAddresses.includes(newAddress))
            .map((address) => addresses[address])
            .filter(Boolean);
          this.form.encrypted =
            recentlyAddedAddresses.some(
              ({ always_encrypt }) => always_encrypt
            ) || this.form.encrypted;
          this.form.signed =
            recentlyAddedAddresses.some(({ always_sign }) => always_sign) ||
            this.form.signed;
        }
      },
      allRecipientsDraft() {
        if (this.draft.to && this.form.to.length > this.draft.to.length) {
          this.form.to = this.draft.to;
        }
        if (this.draft.cc && this.form.cc.length > this.draft.cc.length) {
          this.form.cc = this.draft.cc;
        }
        if (this.draft.bcc && this.form.bcc.length > this.draft.bcc.length) {
          this.form.bcc = this.draft.bcc;
        }
      },
      keyNotAvailableRecipients(recipients) {
        if (recipients.length === 0) {
          this.showPasswordMessageModal = false;
        }
      },
      disablePgpEnhancements(newValue) {
        if (newValue) {
          this.form.attach_public_key = false;
          this.form.encrypted = false;
          this.form.signed = false;
        }
      },
      '$route.params.startTutorial'(newValue) {
        if (newValue === 'true') {
          this.startTutorial();
        }
      },
    },
    async created() {
      this.$root.$on('sendClicked', this.onSendDraft);
      this.$root.$on('delete', this.onDelete);
      //don't put this in methods because that will overwrite the 'clear' method on the function that is returned by debounce
      this.debouncedSaveChanges = debounce(this.saveChanges, 2000);

      if (this.allRecipientsAddresses.length > 0) {
        const { encrypt, sign } = await this.updateKeyState();
        this.form.encrypted = encrypt || this.form.encrypted;
        this.form.signed = sign || this.form.signed;
      }
      this.getDomains();
    },
    mounted() {
      if (document.activeElement) {
        // . Verify if a element is focused
        this.previousElementFocused = document.activeElement; // . return the element that was focused
      }
      // The innerHeight of the window will change when the on screen keyboard is shown
      // on iOS and Android. If this happens, the height of the editor should change so
      // that it will be fully in view.
      // We calculate the actual 'vh' css property that we want to use and set it as a
      // CSS variable on the editor element that is being used by the CSS.
      this.viewportHeightChecker = window.setInterval(() => {
        const vh = window.innerHeight * 0.01;
        this.$refs.editor.$el.style.setProperty('--vh', `${vh}px`);
      }, 300);

      // Only attach watcher after this.data has been set to skip the
      // initial firing of the watcher which would cause an immediate
      // draft save even if there are no changes.
      this.$watch('form', {
        handler() {
          this.version++;
          this.debouncedSaveChanges();
        },
        deep: true,
      });

      this.startTutorial();
    },
    destroyed() {
      this.$root.$off('sendClicked', this.onSendDraft);
      this.$root.$off('delete', this.onDelete);

      this.debouncedSaveChanges.clear();
      this.resetDraft();

      window.clearInterval(this.viewportHeightChecker);
    },
    methods: {
      ...mapActions('authentication', ['getDomains']),
      ...mapActions([
        'saveDraft',
        'sendDraft',
        'resetDraft',
        'setToastMessage',
        'attachFileToDraft',
        'removeFileFromDraft',
        'removeUploadingFilesFromDraft',
        'checkKeys',
        'deleteMessage',
        'deleteRecipient',
        'setDarkModeEditorReset',
      ]),
      invalidEmailAddresses(recipients) {
        return recipients
          ?.filter(
            ({ email, validation_errors = [] }) =>
              !isValid(email) || validation_errors.length
          )
          .map(({ email }) => email);
      },
      async onLeaveRoute(next) {
        if (this.version !== this.versionSaved) {
          try {
            await this.debouncedSaveChanges.asap(false);
          } catch (err) {
            // Failed to save changes while navigating away.
            // Open confirmation dialog if it wasn't already open.
            if (!this.isLeavingWhileNotSaved) {
              this.leaveWhileNotSavedCallback = (leave) => {
                if (!leave) this.isLeavingWhileNotSaved = false;
                next(leave);
              };
              this.isLeavingWhileNotSaved = true;
            }
            return;
          }
        }

        // If an upload is still in progress while navigating away,
        // open confirmation dialog if it wasn't already open.
        if (
          this.isUpdatingAttachment &&
          !this.isLeavingWhileUpdatingAttachment
        ) {
          this.leaveWhileUploadingCallback = (leave) => {
            if (leave) {
              this.removeUploadingFilesFromDraft();
            } else {
              this.isLeavingWhileUpdatingAttachment = false;
            }
            next(leave);
          };
          this.isLeavingWhileUpdatingAttachment = true;
          return;
        }

        next(true);
      },
      async onSendDraft({ passwordProtectedMessage }) {
        if (this.sending) {
          return;
        }
        if (this.aliasFromIncompleteDomain) {
          this.showCompleteDomainModal = true;
          return;
        }

        // set sending draft to true so that saveChanges and sendDraft is seen as one action
        this.$store.commit(SENDING_DRAFT, { sending: true });

        if (this.isUpdatingAttachment) {
          this.$store.commit(SENDING_DRAFT, { sending: false });
          this.setToastMessage({
            message: this.$gettext(
              'You cannot send while uploading or deleting'
            ),
          });
          return;
        }

        const recipients =
          this.form.to.length + this.form.bcc.length + this.form.cc.length;
        if (recipients === 0) {
          this.$store.commit(SENDING_DRAFT, { sending: false });
          this.setToastMessage({
            message: this.$gettext('Please add at least one recipient'),
          });
          return;
        }

        if (!this.selectedAlias) {
          this.$store.commit(SENDING_DRAFT, { sending: false });
          this.setToastMessage({
            message: this.$gettext("Please select 'From' email address"),
          });
          return;
        }

        const invalidEmailAddresses = Array.from(
          new Set([
            ...this.invalidToEmailAddresses,
            ...this.invalidCcEmailAddresses,
            ...this.invalidBccEmailAddresses,
          ])
        );

        if (invalidEmailAddresses.length) {
          this.$store.commit(SENDING_DRAFT, { sending: false });

          return;
        }

        await this.updateKeyState();
        if (this.isEncrypted) {
          if (!this.keyCheckSuccessful) {
            this.$store.commit(SENDING_DRAFT, { sending: false });
            this.setToastMessage({
              message: this.$gettext('The recipient key check failed.'),
            });
            return;
          }

          if (
            this.keyNotAvailableRecipients.length > 0 &&
            !this.showPasswordMessageModal
          ) {
            this.$store.commit(SENDING_DRAFT, { sending: false });
            this.showPasswordMessageModal = true;
            return;
          }
        }

        if (this.form.signed) {
          this.$store.commit(SENDING_DRAFT, { sending: false });
          this.showSigningModal = true;
          this.showPasswordMessageModal = false;
          this.passwordProtectedMessage = passwordProtectedMessage;
          return;
        }

        this.debouncedSaveChanges
          .asap(false)
          .then((draft) =>
            this.sendDraft({
              draftId: draft.id,
              passwordProtectedMessage,
              signPassphrase: null,
            })
          )
          .then(() => {
            if (this.isEncrypted) {
              this.setToastMessage({
                message: this.$gettext('Encrypted message sent'),
              });
            } else {
              this.setToastMessage({ message: this.$gettext('Message sent') });
            }

            if (this.$route.query && this.$route.query.query) {
              this.$router.push({
                name: MAIL_SEARCH,
                query: {
                  query: this.$route.query.query,
                  page: this.$route.query.page || 1,
                },
              });
            } else {
              this.$router.push({
                name: MAIL_FOLDER,
                params: { folder: this.activeFolder.id },
                query: { page: this.$route.query.page || 1 },
              });
            }
          })
          .catch((error) => {
            this.$store.commit(SENDING_DRAFT, { sending: false });
            switch (getErrorCode(error)) {
              case PGP_NO_KEYS_AVAILABLE:
              case PGP_KEY_NOT_AVAILABLE:
                this.setToastMessage({
                  message: this.$gettext(
                    'No public key available to attach to the mail.'
                  ),
                });
                return;
              default:
                this.setToastMessage({
                  message: this.$gettext('Sorry, we could not send your email'),
                });
                throw error;
            }
          });
      },
      onBodyChanged(value) {
        this.form.body = value;
      },
      onExtraFieldsButtonClick() {
        this.extraFieldsFocus = true;

        this.$nextTick(() => {
          this.$refs.cc.focus();
        });
      },
      onExtraFieldsFocus() {
        this.extraFieldsFocus = true;
      },
      onToFieldFocusOut(event) {
        if (!this.$refs.to.$el.contains(event.relatedTarget)) {
          this.truncateRecipientListTo = true;
        }
      },
      onCcFieldFocusOut(event) {
        if (!this.$refs.cc.$el.contains(event.relatedTarget)) {
          this.truncateRecipientListCc = true;
        }
      },
      onBccFieldFocusOut(event) {
        if (!this.$refs.bcc.$el.contains(event.relatedTarget)) {
          this.truncateRecipientListBcc = true;
        }
      },
      showMoreToRecipients() {
        this.$refs.to.focus();
        this.truncateRecipientListTo = false;
      },
      showMoreCcRecipients() {
        this.$refs.cc.focus();
        this.truncateRecipientListCc = false;
      },
      showMoreBccRecipients() {
        this.$refs.bcc.focus();
        this.truncateRecipientListBcc = false;
      },
      removePublicKey() {
        this.form.attach_public_key = false;
      },
      saveChanges(updateComposer = true) {
        this.savingDraft = true;

        if (this.isUpdatingAttachment) {
          this.savingDraft = false;
          return;
        }

        const version = this.version;

        return this.saveDraft({ draft: this.form })
          .then((draft) => {
            if (updateComposer) {
              this.$emit('saved', draft);
              this.versionSaved = version;
              if (
                this.$route.name === MAIL_COMPOSE &&
                this.$route.query.draftId !== draft.id
              ) {
                this.$router.replace({
                  name: MAIL_COMPOSE,
                  query: { ...this.$route.query, draftId: draft.id },
                });
              }
            }
            return draft;
          })
          .catch((error) => {
            this.setToastMessage({
              message: this.$gettext("Draft couldn't be saved"),
            });
            throw error;
          })
          .finally(() => {
            this.savingDraft = false;
          });
      },
      onDelete(deletedRecipient) {
        this.deleteRecipient({ deletedRecipient });
      },
      moreRecipientsButtonText(recipientType) {
        const numTotal = this.form[recipientType].length;
        const numHidden = numTotal - this.truncateRecipientListMaxItems;
        const message = this.$npgettext(
          'recipients',
          '%{ count } more',
          '%{ count } more',
          numHidden
        );
        return this.$gettextInterpolate(message, { count: numHidden });
      },
      async onAttachmentsChanged(files) {
        // if no draft yet, create one
        if (!this.draft.id) {
          this.$emit('change');
          await this.debouncedSaveChanges.asap();
        }

        Array.from(files).forEach((file) => this.attachFile(file));

        this.$refs.file.value = ''; // reset the value of the file input field after each change to allow selecting the same file again
      },
      async onInlineImageInserted({ file, objectUrl }) {
        // if no draft yet, create one
        if (!this.draft.id) {
          this.$emit('change');
          await this.debouncedSaveChanges.asap();
        }

        return this.attachFile(file, objectUrl);
      },
      attachFile(file, objectUrl) {
        return this.attachFileToDraft({
          draftId: this.draft.id,
          file,
          objectUrl,
        })
          .then((response) => {
            if (response) {
              this.form.files = this.attachments;
            } else {
              this.setToastMessage({
                message: this.$gettext(
                  'The file you are trying to attach is already attached to this message'
                ),
              });
            }
          })
          .catch((error) => {
            if (getErrorCode(error) === ATTACHMENT_IS_TOO_BIG) {
              this.setToastMessage({
                message: this.$gettext(
                  'The file size of the selected file(s) is too big'
                ),
              });
            } else {
              this.setToastMessage({
                message: this.$gettext('The file could not be uploaded'),
              });
              throw error;
            }
          });
      },
      onAttachmentRemoved(attachment) {
        return this.removeFileFromDraft({
          draftId: this.draft.id,
          id: attachment.id,
        })
          .then(() => {
            this.form.files = this.attachments;
          })
          .catch((error) => {
            this.setToastMessage({
              message: this.$gettext(
                'Sorry, we are unable to delete the attachment'
              ),
            });
            throw error;
          });
      },
      updateKeyState() {
        return this.checkKeys({
          sender: this.form.from.email,
          addressList: this.allRecipientsAddresses,
        });
      },
      discardDraft: asyncAction('discardingDraft', function () {
        return this.debouncedSaveChanges
          .asap(false)
          .then((message) => {
            this.deleteMessage({ messageId: message.id });
          })
          .then(() => {
            this.$router.push({
              name: MAIL_FOLDER,
              params: { folder: this.activeFolder.id },
              query: { page: this.$route.query.page || 1 },
            });
          });
      }),
      toggleAttachKey() {
        this.form.attach_public_key = !this.form.attach_public_key;
      },
      sendSignedMessage(pgpPassphrase) {
        this.$store.commit(SENDING_DRAFT, { sending: true });
        this.debouncedSaveChanges
          .asap(false)
          .then((draft) =>
            this.sendDraft({
              draftId: draft.id,
              passwordProtectedMessage: this.passwordProtectedMessage,
              signPassphrase: pgpPassphrase,
            })
          )
          .then(() => {
            if (this.isEncrypted) {
              this.setToastMessage({
                message: this.$gettext('Encrypted signed message sent'),
              });
            } else {
              this.setToastMessage({
                message: this.$gettext('Signed message sent'),
              });
            }

            if (this.$route.query && this.$route.query.query) {
              this.$router.push({
                name: MAIL_SEARCH,
                query: {
                  query: this.$route.query.query,
                  page: this.$route.query.page || 1,
                },
              });
            } else {
              this.$router.push({
                name: MAIL_FOLDER,
                params: { folder: this.activeFolder.id },
                query: { page: this.$route.query.page || 1 },
              });
            }
          })
          .catch((error) => {
            this.$store.commit(SENDING_DRAFT, { sending: false });
            switch (getErrorCode(error)) {
              case PGP_BAD_PASSPHRASE:
                this.decryptionBadPassphrase = true;
                break;
              case PGP_NO_KEYS_AVAILABLE:
                this.setToastMessage({
                  message: this.$gettext('No PGP keys are available'),
                });
                break;
              case PGP_KEY_NOT_AVAILABLE:
                this.setToastMessage({
                  message: this.$gettext('The PGP key is not available'),
                });
                break;
              default:
                this.setToastMessage({
                  message: this.$gettext('Something went wrong'),
                });
                throw error;
            }
          });
      },
      resetPgpPassphraseModal() {
        this.showSigningModal = false;
      },
      maybeShowExtraFields(ev) {
        if (ev.dataTransfer && ev.dataTransfer.types.includes('text/json')) {
          this.extraFieldsFocus = true;
        }
      },
      moveToBccField() {
        this.form.bcc = [...this.form.bcc, ...this.form.to, ...this.form.cc];
        this.form.to = [];
        this.form.cc = [];
      },
      incrementTutorialStep() {
        if (this.tutorialStep < this.tutorialStepTotal) {
          this.tutorialStep++;
        } else {
          // reset tutorial steps
          this.tutorialStep = 0;
        }
      },
      startTutorial() {
        if (this.$route.params.startTutorial === 'true') {
          this.incrementTutorialStep();
        }
      },
    },
  };
</script>

<style src="./ComposeForm.scss" lang="scss"></style>
