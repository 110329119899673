<template>
  <Modal
    :modal-is-open="modalIsOpen"
    ref="modal"
    @modalToggled="$emit('modalToggled')"
  >
    <template v-slot:header>
      <translate>Encrypt with a password </translate>
    </template>
    <template v-slot:content>
      <form
        id="passwordMessageForm"
        class="password-protected-message"
        novalidate
        @submit.prevent="onSendEncrypted"
      >
        <p class="user-message">
          <span>
            <translate>
              Set a password to encrypt this message. It can be accessed for 28
              days.
            </translate>
            {{ ' ' }}
            <SupportCenterLink article="360006695898" />
          </span>
        </p>

        <dl class="ppm-form__recipient">
          <dt class="ppm-form__recipient-title">
            <translate
              :translate-n="recipients.length"
              translate-plural="Recipients"
            >
              Recipient </translate
            >:
          </dt>
          <dd class="ppm-form__from-badges">
            <Badge
              v-for="(recipient, index) in recipients"
              :key="recipient"
              :is-removable="true"
              :value="recipient"
              :index="index"
              :show-key-availability="true"
              :key-available="false"
              ref="badges"
              @delete="onDelete(recipient)"
              class="ppm-form__badge"
            />
          </dd>
        </dl>
        <fieldset class="form__group">
          <PasswordField
            id="password"
            :label-text="passwordLabel"
            :validate-input="validatePassword"
            :show-password-strength="true"
            :show-password-strength-message="false"
            v-model="password"
            autocomplete="one-time-code"
          />
          <TextAreaField
            id="personal-message"
            class="form-field"
            :label="textAreaLabel"
            v-model="personalMessage"
          />
          <div class="email-notification-language">
            <label class="form-field__label">
              <translate>Language of the notification email</translate>
            </label>
            <RadioInput
              :options="languageOptions"
              id="language"
              v-model="language"
            />
          </div>
        </fieldset>
        <div class="modal__footer">
          <button
            type="button"
            class="button button--accent"
            @click="$emit('cancelClicked')"
          >
            <translate>Cancel</translate>
          </button>
          <LoadingButton
            type="submit"
            class="button button--primary"
            :disabled="keyNotAvailableRecipients.length === 0"
            :loading="sending"
          >
            {{ confirmText }}
          </LoadingButton>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
  import Badge from '@/components/Badge/Badge';
  import LoadingButton from '@/components/LoadingButton';
  import Modal from '@/components/Modal';
  import PasswordField from '@/components/PasswordField/PasswordField';
  import RadioInput from '@/components/form/RadioInput';
  import TextAreaField from '@/components/form/TextAreaField';
  import SupportCenterLink from '../SupportCenterLink.vue';

  export default {
    name: 'PPMForm', // Password Protected Message Form
    components: {
      Modal,
      PasswordField,
      TextAreaField,
      RadioInput,
      Badge,
      LoadingButton,
      SupportCenterLink,
    },
    props: {
      modalIsOpen: {
        type: Boolean,
        required: true,
      },
      keyNotAvailableRecipients: {
        type: Array,
        required: true,
      },
      sending: {
        type: Boolean,
        required: false,
        default: false,
      },
      isSigned: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        personalMessage: '',
        password: '',
        language: '',
        recipients: [],
        validatePassword: false,
      };
    },
    computed: {
      passwordLabel() {
        return this.$gettext('Message password');
      },
      textAreaLabel() {
        return this.$gettext('Password hint');
      },
      languageOptions() {
        return [
          { name: this.$gettext('English'), value: 'en_US' },
          { name: this.$gettext('German'), value: 'de_DE' },
        ];
      },
      confirmText() {
        if (this.isSigned) {
          return this.$gettext('Proceed to Sign');
        }
        return this.$gettext('Send encrypted');
      },
    },
    watch: {
      password() {
        this.validatePassword = false;
      },
    },
    created() {
      this.language = this.$language.current;
      this.recipients = this.keyNotAvailableRecipients;
    },
    methods: {
      onDelete(deletedRecipient) {
        this.recipients = this.recipients.filter(
          (recipient) => recipient !== deletedRecipient
        );
        this.$root.$emit('delete', deletedRecipient);
      },
      onSendEncrypted(event) {
        this.validatePassword = true;
        event.preventDefault();
        if (!event.target.checkValidity()) {
          return false;
        }
        this.$root.$emit('sendClicked', {
          passwordProtectedMessage: {
            password: this.password,
            message: this.personalMessage,
            notificationLanguage: this.language,
          },
        });
      },
    },
  };
</script>

<style src="./PPMForm.scss" lang="scss" />
