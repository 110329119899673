





































































  import Vue from 'vue';
  import Icon from '@/components/Icon/Icon.vue';
  import ProgressBar from '@/components/ProgressBar/ProgressBar.vue';
  import Spinner from '@/components/Spinner/Spinner.vue';
  import formatDataSize from '@/lib/formatDataSize';

  export default Vue.extend({
    name: 'AttachmentList',
    components: {
      Icon,
      ProgressBar,
      Spinner,
    },
    filters: {
      formatDataSize,
    },
    props: {
      attachments: {
        type: Array,
        required: true,
      },
      showPublicKey: {
        type: Boolean,
        required: true,
      },
    },
    methods: {
      removeAttachmentText(attachment: Attachment) {
        return attachment.state === 'uploading'
          ? this.$gettext('Cancel upload')
          : this.$gettext('Remove attachment');
      },
      onAttachmentRemoved(attachment: Attachment) {
        this.$emit('attachmentRemoved', attachment);
      },
    },
  });
