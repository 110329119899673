/*

This is our custom debounce utility function. Use like so:

    const greet = function(name: string) { console.log('hello ' + name); }
    const debounced = debounce(f, 100);

    debounced('John');
    debounced('Jane');
    .. 100 ms later ..
    > hello Jane

    debounced('Peter');
    debounced.asap('Paul');
    debounced.asap('Mary');
    > hello Mary
    > hello Mary

The function `debounce` returns a debounced version of inner function `func`.
The inner function `func` is called after `interval` milliseconds, unless the
debounced function is called again within that interval. In that case the
scheduled execution of the inner function `func` is cancelled and rescheduled
with new context and arguments and a new interval.

Whenever the inner function `func` is actually called, it is called with the
context and arguments of the last call to the debounced function.

Our debouncer `await`s the inner function `func` when it is executed. And it
schedules *another execution* of the inner function `func` if the debounced
function is called while the inner function `func` is being awaited.

The debounced function returns a pending promise that will resolve with the
return value of the last call into the wrapped function `func`.

*/

export default function debounce(func: Function, interval: number): Function {
  let timeout: number | undefined = undefined;
  let working = false;
  let wantAnotherCall = false;
  let context: any;
  let args: any;
  let lastReturnValue: any = undefined;
  let promise: Promise<any> | undefined = undefined;
  let resolvePromise: Function;
  let rejectPromise: Function;

  const clear = () => {
    if (timeout) {
      window.clearTimeout(timeout);
    }
  };

  const schedule = function (interval: number): Promise<any> {
    if (promise === undefined) {
      promise = new Promise((resolve, reject) => {
        resolvePromise = resolve;
        rejectPromise = reject;
      });
    }

    if (working) {
      // If a call is currently in progress, we register that we want to call
      // the debounced function again when we're done.
      wantAnotherCall = true;
      return promise;
    }

    // If a debounced call is still queued, we extend it.
    clear();
    if (interval == 0) {
      wrapper();
    } else {
      timeout = window.setTimeout(wrapper, interval);
    }

    return promise;
  };

  const debounced = function (this: any): Promise<any> {
    context = this;
    args = arguments;

    return schedule(interval);
  };

  const asap = function (this: any): Promise<any> {
    /*

    Schedules a call for as-soon-as-possible execution.

    The ASAP call will cancel a previous call if it's still waiting
    for its timeout. The ASAP call will then take its place and be
    executed immediately.

    The ASAP call can be delayed if a call into the wrapped function
    is already running. (i.e. working == true) In that case, it will
    run after. Note that in this case it is possible to cancel the
    asap call by doing more calls into either the debounced function
    or the .asap function.

    */
    context = this;
    args = arguments;
    return schedule(0);
  };

  debounced.clear = clear;
  debounced.asap = asap;

  async function wrapper(): Promise<void> {
    working = true;
    timeout = undefined;

    do {
      wantAnotherCall = false;
      try {
        lastReturnValue = await func.apply(context, args);
      } catch (error) {
        if (!wantAnotherCall) {
          working = false;
          promise = undefined;
          rejectPromise(error);
          return;
        } else {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    } while (wantAnotherCall);

    working = false;
    promise = undefined;
    resolvePromise(lastReturnValue);
  }

  return debounced;
}
