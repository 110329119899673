<template>
  <div>
    <AppHeader
      :show-back-button="true"
      @backClicked="onBackClicked"
      class="compose-header"
    >
      <template v-slot:button>
        <div class="app-header__actions">
          <LoadingButton
            v-if="composeReady"
            v-test:sendButton
            class="button button--primary button--icon-only-small-breakpoint app-header__action"
            :loading="sending"
            @click="onSendClicked"
            :key="Boolean(encrypted)"
          >
            <span class="button__text" :key="Boolean(encrypted)">{{
              sendText
            }}</span>
            <Icon symbol="send" />
          </LoadingButton>
        </div>
        <MailSearchForm />
      </template>
    </AppHeader>
  </div>
</template>

<script>
  import Icon from '@/components/Icon/Icon';
  import LoadingButton from '@/components/LoadingButton';
  import MailSearchForm from '@/components/SearchForm/MailSearchForm';
  import folderUtilsMixin from '@/mixins/folder-utils-mixin';
  import { MAIL_FOLDER, MAIL_SEARCH } from '@/router/named-routes';
  import AppHeader from '@/wrappers/AppHeaderWrapper/AppHeaderWrapper';
  import Mousetrap from 'mousetrap';
  import 'mousetrap-global-bind';
  import { mapActions, mapGetters, mapState } from 'vuex';

  export default {
    components: {
      AppHeader,
      Icon,
      LoadingButton,
      MailSearchForm,
    },
    mixins: [folderUtilsMixin],
    data() {
      return {
        composeReady: false,
        encryptedMessage: false,
      };
    },
    computed: {
      ...mapState({
        draft: (state) => state.drafts.draft,
      }),
      ...mapGetters([
        'activeFolder',
        'folderTree',
        'sending',
        'allKeysAvailable',
        'keyNotAvailableRecipients',
        'encrypted',
      ]),
      backFolder() {
        return this.activeFolder || this.folderTree[0];
      },
      backRoute() {
        return this.$route.params.backRoute;
      },
      isSearch() {
        return this.$route.query.query;
      },
      sendText() {
        return this.encryptedMessage
          ? this.$gettext('Send encrypted')
          : this.$gettext('Send');
      },
    },
    watch: {
      encrypted(value) {
        this.encryptedMessage = value;
      },
    },
    created() {
      Mousetrap.bindGlobal(['ctrl+enter', 'command+enter'], this.onSendClicked);
      Mousetrap.bindGlobal('esc', this.onBackClicked);
      this.encryptedMessage = this.draft.encrypted;
      this.$root.$on('composeReady', () => {
        this.composeReady = true;
      });
      this.$root.$on('encryptionEnabledChanged', (value) => {
        this.encryptedMessage = value;
      });
    },
    destroyed() {
      Mousetrap.unbindGlobal(['ctrl+enter', 'command+enter', 'esc']);
      this.$root.$off('encryptionEnabledChanged');
    },
    methods: {
      ...mapActions(['setToastMessage']),
      onBackClicked() {
        if (this.backRoute) {
          return this.$router.push(this.backRoute);
        }
        const route = this.isSearch
          ? {
              name: MAIL_SEARCH,
              query: {
                query: this.$route.query.query,
                page: this.$route.query.page,
              },
            }
          : {
              name: MAIL_FOLDER,
              params: { folder: this.backFolder.id },
              query: { page: this.$route.query.page },
            };

        this.$router.push(route);
      },
      onSendClicked() {
        this.$root.$emit('sendClicked', { passwordProtectedMessage: null });
      },
    },
  };
</script>

<style src="./ComposeHeader.scss" lang="scss" />
