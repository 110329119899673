export default function formatDataSize(number: number): string {
  if (number < 1024) {
    return number + ' bytes';
  } else if (number < 1048576) {
    return (number / 1024).toFixed(1) + 'KB';
  } else if (number < 1073741824) {
    return (number / 1048576).toFixed(1) + 'MB';
  } else {
    return (number / 1073741824).toFixed(1) + 'GB';
  }
}
