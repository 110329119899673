































  import Vue from 'vue';
  import debounce from 'debounce';
  import api from '@/api';
  import SuggestionItem from '@/components/SuggestionItem/SuggestionItem.vue';

  interface Suggestion {
    contact: Contact;
    email: string;
    name: string;
    matches: {
      field: string;
      index: number;
      length: number;
    }[];
  }

  export default Vue.extend({
    components: {
      SuggestionItem,
    },
    props: {
      excludeSuggestions: {
        type: Array,
        default: () => [],
      },
      id: {
        type: String,
        required: true,
      },
      inputValue: {
        type: String,
        required: true,
      },
      minLength: {
        type: Number,
        default: 3,
      },
    },
    data() {
      return {
        current: -1,
        isOpen: false,
        suggestions: [],
      };
    },
    computed: {
      selectedSuggestion(): Suggestion | null {
        return this.isOpen ? this.filteredSuggestions[this.current] : null;
      },
      filteredSuggestions(): Suggestion[] {
        /* Filter the suggestion only if the suggestion contains a single email address and that email address is already entered in the field.
         *  Grouped suggestions should not be filtered.
         * excludeSuggestions contains list of already entered email addresses. */
        return this.suggestions.filter(
          (suggestion: ContactSuggestionItem) =>
            suggestion.suggestion_type === 'group' ||
            (suggestion.addresses.length === 1 &&
              !this.excludeSuggestions.includes(suggestion.addresses[0].email))
        );
      },
    },
    watch: {
      inputValue() {
        this.suggestions = [];
        this.current = -1;
        this.getSuggestions();
      },
      suggestions(newValue: Suggestion[], oldValue: Suggestion[]) {
        if (newValue.length !== oldValue.length) {
          this.isOpen = newValue.length > 0;
          this.$emit('autocompleteListToggled', this.isOpen);
        }
      },
      current() {
        this.$emit('suggestionChanged', this.selectedSuggestion);
      },
    },
    methods: {
      suggestionClick(index: number) {
        this.current = index;
        this.$emit('suggestionSelected', { value: this.selectedSuggestion });
      },
      getSuggestions: debounce(function (this: any) {
        if (this.inputValue.length >= this.minLength) {
          api.recipients
            .suggest({ query: this.inputValue })
            .then((suggestions) => {
              this.suggestions = suggestions.map(
                (recipient: ContactSuggestionItem) => {
                  return {
                    addresses: recipient.addresses,
                    name: recipient.display_name,
                    suggestion_type: recipient.suggestion_type,
                  };
                }
              );
              this.$emit('suggestionChanged', this.selectedSuggestion);
            });
        }
      }, 300),
      previous() {
        if (this.current > 0) {
          this.current--;
        }
      },
      next() {
        if (this.current < this.suggestions.length - 1) {
          this.current++;
        }
      },
      close() {
        this.suggestions = [];
      },
      isActive(index: number) {
        return index === this.current;
      },
    },
  });
