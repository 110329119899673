








  import Vue from 'vue';

  export default Vue.extend({
    props: {
      article: {
        type: String,
        required: false,
        default: undefined,
      },
    },
    computed: {
      defaultText(): string {
        return this.$gettext('Learn more');
      },
      href(): string {
        return this.article
          ? this.$gettextInterpolate(
              this.$gettext(
                'https://support.startmail.com/hc/en-us/articles/%{ article }'
              ),
              { article: this.article }
            )
          : this.$gettext('https://support.startmail.com');
      },
    },
  });
