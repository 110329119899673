<template>
  <div :class="withPopperClass">
    <slot />
    <div class="popper" v-if="isCurrentStep">
      <div class="popper__content">
        <p><slot name="popper" /></p>
      </div>
      <div class="popper__actions">
        <span class="popper__steps">{{ stepProgressText }}</span>
        <button
          @click="nextButtonCallback"
          class="button button--primary app-header__action"
          v-test:tutorial-continue-button
        >
          {{ nextButtonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import api from '@/api';

  export default Vue.extend({
    name: 'Tutorial',
    props: {
      tutorialStep: {
        type: Number,
        required: true,
      },
      tutorialStepTotal: {
        type: Number,
        required: true,
      },
      isCurrentStep: {
        type: Boolean,
        required: true,
      },
      specialClass: {
        type: String,
        required: true,
      },
    },
    computed: {
      withPopperClass() {
        const showArrow = this.isCurrentStep ? 'popper-open' : '';
        return `with-popper ${this.specialClass} ${showArrow}`;
      },
      isLastStep() {
        return this.tutorialStep === this.tutorialStepTotal;
      },
      nextButtonText() {
        return this.isLastStep ? this.$gettext('Done') : this.$gettext('Next');
      },
      stepProgressText() {
        return this.$gettextInterpolate(
          this.$gettext('%{ current } of %{ total }'),
          {
            current: this.tutorialStep,
            total: this.tutorialStepTotal,
          }
        );
      },
    },
    methods: {
      nextButtonCallback() {
        if (this.isLastStep) {
          api.uiEvents.create({
            event_type: 'tutorial_poppers_done',
          });
        }
        this.$emit('next');
      },
    },
  });
</script>

<style src="./Tutorial.scss" lang="scss"></style>
