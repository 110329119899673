










































  import Vue from 'vue';
  import Icon from '@/components/Icon/Icon.vue';

  export default Vue.extend({
    name: 'ComposeSelectInput',
    components: { Icon },
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        default: '',
      },
      options: {
        type: Array,
        required: true,
      },
      showCustomValidity: {
        type: Boolean,
        default: false,
      },
      validate: {
        type: Boolean,
        default: false,
      },
      validationErrorMessage: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        valid: false,
      };
    },
    computed: {
      native(): HTMLSelectElement {
        return this.$refs.native as HTMLSelectElement;
      },
      isInvalid(): boolean {
        return this.validate && !this.valid;
      },
    },
    watch: {
      value() {
        this.setCustomValidity();
        /* when the value is updated programatically, the input is not updated
         right away. so we check for validity on the next tick to make sure it
         has the right value. */
        this.$nextTick(() => {
          this.valid = this.native.checkValidity();
        });
      },
      showCustomValidity() {
        this.setCustomValidity();
        /* somehow when setting the customvalidity, the input element does not
         report that it is invalid until the next event loop, so we delay the
         check a bit. */
        setTimeout(() => {
          this.valid = this.native.checkValidity();
        }, 0);
      },
    },
    mounted() {
      this.setCustomValidity();

      setTimeout(() => {
        this.valid = this.native.checkValidity();
      }, 0);
    },
    methods: {
      setCustomValidity() {
        this.native.setCustomValidity(
          this.showCustomValidity ? this.validationErrorMessage : ''
        );
      },
    },
  });
