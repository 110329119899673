<template>
  <SearchInputField
    :label="searchLabelText"
    :initial-query="$route.query.query"
    @search="search"
  />
</template>

<script>
  import SearchInputField from '@/components/SearchForm/SearchInputField';
  import { MAIL_SEARCH } from '@/router/named-routes';

  export default {
    name: 'MailSearchForm',
    components: {
      SearchInputField,
    },
    computed: {
      searchLabelText() {
        return this.$gettext('Search Mail');
      },
    },
    methods: {
      search(query) {
        if (query) {
          this.$router.push({
            name: MAIL_SEARCH,
            query: {
              query,
            },
          });
        }
      },
    },
  };
</script>
