


















  import Vue from 'vue';

  export default Vue.extend({
    name: 'TextAreaField',
    model: {
      prop: 'value',
      event: 'input',
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      rows: {
        type: Number,
        default: 5,
      },
      value: {
        type: String,
        required: true,
      },
    },
  });
