const needsToBeQuoted = new RegExp("^[\\-\\w !#$%&'*+/=?^`{|}~]+$");

interface Recipient {
  email: string;
  name?: string;
}

export default function (
  { name, email }: Recipient,
  hideEmail = false
): string {
  if (hideEmail) {
    return name || email;
  }
  if (name) {
    if (!needsToBeQuoted.test(name)) {
      name = '"' + name + '"';
    }
    return `${name} <${email}>`;
  }
  return email;
}
