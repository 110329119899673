


















































































































































  import Vue, { PropType } from 'vue';
  import axios from 'axios';
  import debounce from 'debounce';
  import { mapActions, mapGetters } from 'vuex';
  import api, { AliasType } from '@/api';
  import {
    ALIAS_EMAIL_IN_USE,
    ALIAS_INVALID_EMAIL,
    ALIAS_NAME_EXCEEDS_LIMIT,
    ALIAS_NOTE_EXCEEDS_LIMIT,
  } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import Icon from '@/components/Icon/Icon.vue';
  import InputField from '@/components/form/InputField.vue';
  import RadioInput from '@/components/form/RadioInput.vue';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import { getItem, setItem } from '@/lib/localStorage';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import TextAreaField from '@/components/form/TextAreaField.vue';
  import SelectInput from '@/components/form/SelectInput.vue';
  import MultiSelectInput from '@/components/MultiSelectInput/MultiSelectInput.vue';
  import SupportCenterLink from './SupportCenterLink.vue';

  export default Vue.extend({
    name: 'CustomAliasModal',
    components: {
      Icon,
      InputField,
      Modal,
      ModalConfirmActions,
      MultiSelectInput,
      RadioInput,
      SelectInput,
      SupportCenterLink,
      TextAreaField,
    },
    mixins: [asyncActionsMixin],
    props: {
      source: {
        /* the source alias for editing */
        type: Object as PropType<Alias>,
        default: null,
      },
      type: {
        type: String as PropType<string>,
        required: true,
        validator: (value) =>
          typeof value === 'string' && ['personal', 'group'].includes(value),
      },
    },
    data() {
      return {
        localPart: '',
        domainPart: '',

        displayName: '',
        note: '',
        selectedExpiryTime: '0',
        recipients: [] as string[],

        errorCode: null as number | null,
        loading: true,
        showCustomValidity: false,
        validate: false,
        localPartSuggestion: '',
        checkingAliasAvailability: false,
      };
    },
    computed: {
      ...mapGetters('authentication', ['isManager']),
      ...mapGetters('aliases', ['allowedAliasDomainNamesForType']),
      expiryFieldsVisible(): boolean {
        // New domain aliases cannot have expiry anymore, but existing
        // ones with expiry can still be updated. Hopefully there will
        // be none left Real Soon Now™.
        return this.type !== 'group' || Boolean(this.source?.expires);
      },
      recipientOptions(): HTMLSelectOption[] {
        return (
          this.$store.state.subscription.groupSubscription
            ?.accounts as AccountDetails[]
        )
          .map((ad) => ({
            name: `${ad.display_name} <${ad.email}>`,
            value: ad.email,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
      },
      domainOptions(): HTMLSelectOption[] {
        return (this.allowedAliasDomainNamesForType as Function)(this.type).map(
          (name: string) => ({ value: name, name: `@${name}` })
        );
      },
      hasMultipleDomains(): boolean {
        return this.domainOptions.length > 1;
      },
      expires(): string | null {
        if (this.selectedExpiryTime === null || this.selectedExpiryTime === '0')
          return null;
        const expiresTimestamp =
          new Date().getTime() +
          Number.parseInt(this.selectedExpiryTime) * 60000;
        return new Date(expiresTimestamp).toISOString();
      },
      chosenDomainPart(): string {
        return this.hasMultipleDomains
          ? this.domainPart
          : this.domainOptions[0]?.value ?? '';
      },
      chosenEmailAddress(): string {
        return `${this.localPart}@${this.chosenDomainPart}`;
      },
      aliasValidationError(): string {
        if (this.errorCode === ALIAS_EMAIL_IN_USE) {
          return this.$gettext('Address is unavailable');
        }
        if (this.errorCode === ALIAS_INVALID_EMAIL) {
          return this.$gettext('Invalid email address');
        }
        if (!this.localPart) {
          return this.$gettext('Alias is required');
        }
        return '';
      },
      expiryOptions(): HTMLSelectOption[] {
        return [
          {
            name: this.$gettext('1 hour'),
            value: '60',
          },
          {
            name: this.$gettext('1 day'),
            value: (24 * 60).toString(),
          },
          {
            name: this.$gettext('1 week'),
            value: (7 * 24 * 60).toString(),
          },
          {
            name: this.$gettext('1 month'),
            value: (31 * 24 * 60).toString(),
          },
          {
            name: this.$gettext('Never'),
            value: '0',
          },
        ];
      },
      okText(): string {
        return this.editModal
          ? this.$gettext('Update alias')
          : this.createAliasText;
      },
      createAliasText(): string {
        return this.$gettext('Create alias');
      },
      editAliasText(): string {
        return this.$gettext('Edit alias');
      },
      displayNameText(): string {
        return this.$gettext('Display name (optional)');
      },
      noteText(): string {
        return this.$gettext('Note (optional)');
      },
      recipientsText(): string {
        return this.$gettext('Deliver to');
      },
      editModal(): boolean {
        return Boolean(this.source);
      },
      selectRecipients(): string {
        return this.$gettext('Select recipients');
      },
    },
    watch: {
      localPart() {
        this.resetErrorState();
      },
      selectedDomain() {
        this.showCustomValidity = false;
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      ...mapActions('authentication', ['getAuthenticationStatus']),

      async onModalToggled(open: boolean) {
        if (!open) {
          return;
        }

        this.resetErrorState();
        if (this.source) {
          // edit existing alias
          [this.localPart, this.domainPart] = this.source.alias.split('@', 2);
          this.displayName = this.source.displayName;
          this.recipients = [...this.source.recipients];
          this.note = this.source.note;
          this.selectedExpiryTime = '';
        } else {
          // create new alias
          this.selectedExpiryTime =
            (await getItem('sm-alias-expiration-time')) ?? '0';
          this.domainPart = this.domainOptions[0]?.value ?? '';
          this.localPart = '';
          this.recipients = [this.$store.state.authentication.user.email];
          this.displayName = '';
          this.note = '';
          this.localPartSuggestion = [...Array(10)]
            .map(
              () => 'abcdefghijklmnopqrstuvwxyz'[Math.floor(Math.random() * 26)]
            )
            .join('');
        }
      },

      resetErrorState() {
        this.validate = false;
        this.showCustomValidity = false;
        this.errorCode = null;
      },

      async submitForm(this: any, toggle: Function) {
        this.validate = true;
        if (
          !this.$refs.form.checkValidity() ||
          this.checkingAliasAvailability
        ) {
          return;
        }
        await (this.source ? this.updateAlias : this.createAlias)();
        if (!this.errorCode) {
          toggle();
        }
      },
      buildRequestBody({ preflight = false } = {}): CreateAliasRequest {
        return {
          alias: this.chosenEmailAddress,
          display_name: this.displayName,
          note: this.note,
          preflight,
          recipients: this.recipients,
          ...(this.type === 'personal'
            ? {
                expires: this.expires,
                type: AliasType.personal,
              }
            : {
                expires: null,
                type: AliasType.group,
              }),
        };
      },
      async createAlias() {
        this.errorCode = null;
        try {
          const createdAlias = await api.aliases.create(
            this.buildRequestBody()
          );
          await this.getAuthenticationStatus();
          await this.$store.dispatch('aliases/loadAliases');
          this.setToastMessage({ message: this.$gettext('Alias created.') });
          this.$emit('created', createdAlias);
          api.uiEvents.create({
            event_type:
              this.type === 'personal'
                ? 'alias_created_personal'
                : 'alias_created_domain',
          });
        } catch (err) {
          if (!axios.isAxiosError(err)) throw err;
          this.errorCode = getErrorCode(err);
          if (
            [ALIAS_EMAIL_IN_USE, ALIAS_INVALID_EMAIL].includes(
              this.errorCode as number
            )
          ) {
            this.showCustomValidity = true;
          } else {
            if (this.errorCode === ALIAS_NAME_EXCEEDS_LIMIT) {
              this.setToastMessage({
                message: this.$gettext(
                  'Display name is too long (max. 255 characters)'
                ),
              });
            } else if (this.errorCode === ALIAS_NOTE_EXCEEDS_LIMIT) {
              this.setToastMessage({
                message: this.$gettext(
                  'Alias note is too long (max. 4096 characters)'
                ),
              });
            } else {
              this.setToastMessage({
                message: this.$gettext(
                  'Unable to create alias, please try again'
                ),
              });
            }
            throw err;
          }
        }
      },
      async updateAlias() {
        const update: UpdateAliasRequest = {
          display_name: this.displayName,
          enabled: this.enabled,
          note: this.note,
          recipients: this.recipients,
        };
        if (
          this.selectedExpiryTime !== null &&
          this.selectedExpiryTime !== ''
        ) {
          update.expires = this.expires;
        }

        this.errorCode = null;
        try {
          const updatedAlias = await api.aliases.update({
            id: this.source.id,
            update,
          });
          this.$emit('updated', updatedAlias);
          this.setToastMessage({ message: this.$gettext('Alias updated') });
        } catch (err) {
          if (!axios.isAxiosError(err)) throw err;
          this.errorCode = getErrorCode(err);
          if (this.errorCode === ALIAS_NAME_EXCEEDS_LIMIT) {
            this.setToastMessage({
              message: this.$gettext(
                'Display name is too long (max. 255 characters)'
              ),
            });
          } else if (this.errorCode === ALIAS_NOTE_EXCEEDS_LIMIT) {
            this.setToastMessage({
              message: this.$gettext(
                'Alias note is too long (max. 4096 characters)'
              ),
            });
          } else {
            this.setToastMessage({
              message: this.$gettext(
                'Unable to update alias, please try again'
              ),
            });
          }
          throw err;
        }
      },
      onCustomAliasInput() {
        this.showCustomValidity = false;
        this.checkingAliasAvailability = true;
        this.checkAliasAvailabilityDebounced();
      },
      checkAliasAvailabilityDebounced: debounce(async function (this: any) {
        try {
          this.checkAliasAvailability();
        } finally {
          this.checkingAliasAvailability = false;
        }
      }, 300),
      checkAliasAvailability: asyncAction(
        'checkAliasAvailability',
        async function (this: any) {
          try {
            return await api.aliases.create(
              this.buildRequestBody({ preflight: true })
            );
          } catch (err) {
            if (!axios.isAxiosError(err)) throw err;
            this.validate = true;
            this.errorCode = getErrorCode(err);
            if (
              [ALIAS_EMAIL_IN_USE, ALIAS_INVALID_EMAIL].includes(
                this.errorCode as number
              )
            ) {
              this.showCustomValidity = true;
              return;
            }
            throw err;
          }
        }
      ),
      async selectExpiryTime(exp: number) {
        await setItem('sm-alias-expiration-time', exp);
      },
    },
  });
