<template>
  <div class="progress-bar">
    <div v-if="showPercentage" class="progress-bar__percentage">
      {{ Math.round(valueAsPercentage) }}% {{ completeText }}
    </div>
    <div
      class="progress-bar__bar"
      :class="{ 'progress-bar--indeterminate': indeterminate }"
      role="progressbar"
      :aria-valuenow="indeterminate ? null : `${Math.round(valueAsPercentage)}`"
      :aria-valuemin="indeterminate ? null : 0"
      :aria-valuemax="indeterminate ? null : 100"
    >
      <div class="progress-bar__progress" :style="progressStyle" />
    </div>
  </div>
</template>

<script>
  const UPDATE_INTERVAL_MS = 100;

  export default {
    name: 'ProgressBar',
    props: {
      value: {
        validator(v) {
          return typeof v === 'undefined' || typeof v === 'number';
        },
        default: undefined,
      },
      estimatedDurationMilliseconds: {
        type: Number,
        default: undefined,
      },
      showPercentage: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        estimatedValue: 0,
      };
    },
    computed: {
      indeterminate() {
        return (
          typeof this.value === 'undefined' &&
          !this.estimatedDurationMilliseconds
        );
      },
      valueAsPercentage() {
        const v = this.value === undefined ? this.estimatedValue : this.value;
        return Math.min(Math.max(v * 100, 0), 100);
      },
      progressStyle() {
        return this.indeterminate
          ? null
          : { width: `${this.valueAsPercentage.toFixed(2)}%` };
      },
      progressStep() {
        return UPDATE_INTERVAL_MS / this.estimatedDurationMilliseconds;
      },
      completeText() {
        return this.$gettext('complete');
      },
    },
    watch: {
      value: 'stopUpdatingProgress',
    },
    mounted() {
      if (this.estimatedDurationMilliseconds) {
        this.progressUpdater = window.setInterval(
          this.updateProgress.bind(this),
          UPDATE_INTERVAL_MS
        );
      }
    },
    methods: {
      updateProgress() {
        if (this.estimatedValue >= 0.9) {
          this.stopUpdatingProgress();
        } else {
          this.estimatedValue = Math.min(
            this.estimatedValue + this.progressStep,
            0.9
          );
        }
      },
      stopUpdatingProgress() {
        if (this.progressUpdater) {
          window.clearInterval(this.progressUpdater);
          this.progressUpdater = null;
        }
      },
    },
  };
</script>

<style src="./ProgressBar.scss" lang="scss"></style>
