<template>
  <div
    class="compose-input-field"
    :class="{
      'compose-input-field--target': dropTarget,
    }"
    @dragover="activateDropTarget"
    @dragleave="deactivateDropTarget"
    @drop="onDrop"
  >
    <label class="compose-input-field__label" :for="id">
      <strong>{{ label }}:</strong>
    </label>

    <div :id="`${id}-badge`" class="compose-input-field__wrapper">
      <div class="compose-input-field__recipient-wrapper">
        <RecipientBadge
          v-for="(recipient, index) in visibleRecipientBadges"
          :key="`${id}-${index}`"
          :id="`${id}-${index}`"
          class="compose-input-field__badge"
          ref="badges"
          :value="recipient"
          :index="index"
          :show-key-availability="showKeyAvailability"
          :is-removable="true"
          :is-invalid="isInvalidRecipient(recipient)"
          :is-key-available="keyAvailableForRecipient(recipient)"
          :hide-input-field="hideInputField"
          :draggable="true"
          :show-badge-dropdown="showBadgeDropdown"
          @blur="onInputSubmit"
          @delete="onBadgeDelete(index)"
          @focus="onInputFocus"
          @input="onChangeEmail(recipient, $event)"
          @edit="onBadgeEdit(index, $event)"
          @keydown.native.left.prevent="focusBadge(index - 1)"
          @keydown.native.right.prevent="focusBadge(index + 1)"
          @dragstart="onDragStart"
          @drag="onDrag"
          @dragend="onDragEnd"
        />

        <div class="compose-input__wrapper">
          <input
            class="compose-input-field__input"
            :id="id"
            ref="input"
            :name="id"
            :value="inputFieldValue"
            v-bind="$attrs"
            aria-autocomplete="list"
            :aria-controls="autocompleteId"
            :aria-labelledby="inputLabelId"
            :aria-activedescendant="suggestion"
            inputmode="email"
            @input="updateInput($event.target.value)"
            @blur="onInputSubmit"
            @focus="onInputFocus"
            @keydown.delete="onInputDelete"
            @keydown.enter="onInputSubmit"
            @paste.prevent="onInputPaste"
            @keydown.delete.exact="$emit('delete', $event)"
            @keydown.esc.exact="onEscape"
            @keydown.enter.exact="
              autocompleteIsOpen && suggestion
                ? onSuggestionSelected({ event: $event, value: suggestion })
                : $emit('submit', $event)
            "
            @keydown.tab.exact="nextSuggestion($event)"
            @keydown.shift.tab="previousSuggestion"
            @keydown.down.exact="nextSuggestion($event)"
            @keydown.up.exact="previousSuggestion"
          />
          <Autocomplete
            :id="autocompleteId"
            ref="autocomplete"
            :input-value="inputFieldValue"
            :aria-labelledby="inputLabelId"
            :exclude-suggestions="autoCompleteOptions.excludeSuggestions"
            @autocompleteListToggled="onAutocompleteListToggled"
            @suggestionChanged="onSuggestionChanged"
            @suggestionSelected="onSuggestionSelected"
          />
        </div>
      </div>

      <p
        v-if="hint || isInvalid"
        class="compose-input-field__hint"
        :class="{ 'compose-input-field__hint--invalid': isInvalid }"
        v-test:inputFieldHint
      >
        {{ isInvalid ? validationErrorMessage : hint }}
      </p>
    </div>

    <slot name="button" />
  </div>
</template>

<script>
  import Autocomplete from '@/components/Autocomplete/Autocomplete.vue';
  import formatRecipient from '@/lib/formatRecipient';
  import { toRecipient, toRecipientList } from '@/lib/mailAddress';
  import RecipientBadge from '@/components/RecipientBadge/RecipientBadge';

  export default {
    components: {
      Autocomplete,
      RecipientBadge,
    },
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      truncate: {
        type: Boolean,
        required: true,
      },
      truncateMaxItems: {
        type: Number,
        required: true,
      },
      showKeyAvailability: {
        type: Boolean,
        required: false,
        default: false,
      },
      tabindex: {
        type: [Number, String],
        required: false,
        default: 0,
      },
      value: {
        type: Array,
        required: false,
        default: () => [],
      },
      invalidEmailAddresses: {
        type: Array,
        required: false,
        default: () => [],
      },
      hint: {
        type: String,
        default: '',
      },
      validate: {
        type: Boolean,
      },
      validationErrorMessage: {
        type: String,
        default: '',
      },
      keyState: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      hideInputField: {
        type: Boolean,
        required: false,
        default: false,
      },
      showBadgeDropdown: {
        type: Boolean,
        required: false,
        default: true,
      },
      startFocussed: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        inputFieldValue: '',
        autocompleteIsOpen: false,
        dropTarget: false,
        suggestion: '',
        activeBadge: false,
        isEligibleDropTarget: true,
      };
    },
    computed: {
      recipientBadges: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('change', value);
        },
      },
      visibleRecipientBadges() {
        return this.recipientBadges.slice(
          0,
          this.truncate ? this.truncateMaxItems : undefined
        );
      },
      selectedEmailAddresses() {
        return this.recipientBadges.map((badge) => badge.email);
      },
      isInvalid() {
        return this.validate && this.invalidEmailAddresses.length > 0;
      },
      autocompleteId() {
        return `${this.id}-listbox`;
      },
      inputLabelId() {
        return `${this.id}-label`;
      },
      autoCompleteOptions() {
        return {
          excludeSuggestions: this.recipientBadges.map((badge) => badge.email),
        };
      },
      autocompleteComponent() {
        return this.$refs.autocomplete;
      },
    },
    methods: {
      formatRecipient,
      onInputPaste(e) {
        const text = e.clipboardData.getData('text/plain');

        const newRecipientBadges = toRecipientList(text);

        if (!newRecipientBadges.length) {
          return;
        }
        this.inputFieldValue += formatRecipient(newRecipientBadges.pop());

        this.addRecipientBadge([...newRecipientBadges]);
      },
      onAutocompleteListToggled(isOpen) {
        this.autocompleteIsOpen = isOpen;
      },
      onBadgeDelete(index) {
        this.removeRecipientBadge(index);
        this.focusBadge(index);
      },
      onBadgeEdit(index, emailAddress) {
        this.removeRecipientBadge(index);
        this.$refs.input.focus();
        this.inputFieldValue = emailAddress;
      },
      onInputDelete(ev) {
        if (this.inputFieldValue === '' && this.$refs.badges) {
          ev.preventDefault();
          this.focusBadge(this.$refs.badges.length - 1);
        }
      },
      onInputFocus() {
        this.activeBadge = true;
        this.$emit('focus');
      },
      onInputKeydown(ev) {
        if ([',', ';'].includes(ev.key)) {
          ev.preventDefault();
          this.onInputSubmit();
        }
        if (ev.key === 'ArrowLeft' && this.getCaretPosition(ev.target) === 0) {
          this.focusBadge(this.$refs.badges.length - 1);
        }
      },
      getCaretPosition(el) {
        const range = window.getSelection().getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(el);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        return preCaretRange.toString().length;
      },
      onSuggestionChanged(suggestion) {
        this.suggestion = suggestion;
      },
      onSuggestionSelected({ event, value }) {
        if (event) {
          event.preventDefault();
        }
        this.suggestion = value;
        this.inputFieldValue = this.suggestion.addresses.map(formatRecipient);

        this.onInputSubmit();
      },
      onChangeEmail(recipient, event) {
        this.recipientBadges = this.recipientBadges.map((item) => {
          if (item !== recipient) {
            return item;
          }
          return { ...item, email: event.email };
        });
      },
      onInputSubmit() {
        if (this.suggestion) {
          this.addRecipientBadge(this.suggestion.addresses);
        } else if (this.inputFieldValue.trim()) {
          this.addRecipientBadge([toRecipient(this.inputFieldValue)]);
        }

        this.suggestion = null;
        this.inputFieldValue = '';
      },
      isInvalidRecipient(recipient) {
        return this.invalidEmailAddresses.includes(recipient.email);
      },
      removeRecipientBadge(index) {
        this.recipientBadges = this.recipientBadges.filter(
          (item, i) => i !== index
        );
      },
      addRecipientBadge([...badges]) {
        this.recipientBadges = [...this.recipientBadges, ...badges];
      },
      keyAvailableForRecipient(recipient) {
        if (this.showKeyAvailability) {
          return this.keyState[recipient.email];
        }
        return false;
      },
      onEscape(ev) {
        if (this.autocompleteIsOpen) {
          ev.stopPropagation();
          this.autocompleteComponent.close();
        }
      },
      focusBadge(index) {
        if (this.$refs.badges[index]) {
          this.$refs.badges[index].focus();
        } else if (index > 0) {
          this.$refs.input.focus();
        }
      },
      isActiveRecipient(recipient) {
        return this.recipientBadges
          .map((recipientBadge) => recipientBadge.email)
          .includes(recipient.email);
      },
      onDragStart({ event, recipient }) {
        // Make sure this component is not available as a drop target
        this.isEligibleDropTarget = false;
        event.dataTransfer.setData('text/json', JSON.stringify(recipient));
        event.dataTransfer.effectAllowed = 'move';
      },
      onDrag() {
        // emit change while dragging to make sure that saving is debounced
        this.$emit('change', this.value);
      },
      onDragEnd({ event, recipient }) {
        this.isEligibleDropTarget = true;
        // Due to a bug in Edge, the dropEffect is still 'none', so we always
        // remove the badge even if it is not dragged into a drop area
        if (
          event.dataTransfer.dropEffect === 'move' ||
          window.navigator.userAgent.includes('Edge')
        ) {
          const index = this.recipientBadges.findIndex(
            ({ email }) => email === recipient.email
          );
          this.removeRecipientBadge(index);
        }
      },
      activateDropTarget(ev) {
        if (
          this.isEligibleDropTarget &&
          ev.dataTransfer &&
          ev.dataTransfer.types.includes('text/json')
        ) {
          this.dropTarget = true;
          ev.preventDefault();
          ev.dataTransfer.dropEffect = 'move';
        }
      },
      deactivateDropTarget() {
        this.dropTarget = false;
      },
      nextSuggestion(ev) {
        if (this.autocompleteIsOpen) {
          ev.preventDefault();
          this.autocompleteComponent.next();
        }
      },
      previousSuggestion(ev) {
        if (this.autocompleteIsOpen) {
          ev.preventDefault();
          this.autocompleteComponent.previous();
        }
      },
      onDrop(ev) {
        this.deactivateDropTarget();
        if (
          ev.dataTransfer &&
          ev.dataTransfer.types.includes('text/json') &&
          !this.isActiveRecipient(
            JSON.parse(ev.dataTransfer.getData('text/json'))
          )
        ) {
          ev.preventDefault();
          this.addRecipientBadge([
            JSON.parse(ev.dataTransfer.getData('text/json')),
          ]);
        }
      },
      relativeTabindex(index) {
        if (typeof this.tabindex === 'number' && this.tabindex > 0) {
          return this.tabindex - this.recipientBadges.length + index;
        }

        return this.tabindex;
      },
      updateInput(value) {
        this.suggestion = '';
        this.inputFieldValue = value;
        this.$emit('input', value);
      },
      focus() {
        this.$refs.input.focus();
      },
    },
    mounted() {
      if (this.startFocussed) {
        this.focus();
      }
    },
  };
</script>

<style src="./ComposeInputField.scss" lang="scss"></style>
