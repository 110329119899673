import Vue from 'vue';
import { mapActions } from 'vuex';

const CONFIRM_NAVIGATION_BEFOREUNLOAD_ID = 'CONFIRM_NAVIGATION_ID';

export default Vue.extend({
  data() {
    return {
      isDirty: false,
    };
  },
  mounted() {
    // Add function to beforeunload store module in order to removeEventListener in other places too
    this.addBeforeunloadFunction({
      [CONFIRM_NAVIGATION_BEFOREUNLOAD_ID]: this.onBeforeUnload,
    });
    window.addEventListener('beforeunload', this.onBeforeUnload, false);
  },
  destroyed() {
    // When removing event listener here, no need to keep it in the beforeunload store module
    this.removeBeforeunloadFunction(CONFIRM_NAVIGATION_BEFOREUNLOAD_ID);
    window.removeEventListener('beforeunload', this.onBeforeUnload, false);
  },
  methods: {
    ...mapActions(['removeBeforeunloadFunction', 'addBeforeunloadFunction']),
    /**
     * Returning anything other then undefined will show a popup with a predefined text (this text cannot be changed)
     * See: https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
     */
    onBeforeUnload(ev: Event) {
      if (this.isDirty) {
        ev.preventDefault();
      }
    },
    setDirty(value: boolean) {
      this.isDirty = value;
    },
  },
});
