import { render, staticRenderFns } from "./RecipientBadge.vue?vue&type=template&id=ce97b560&"
import script from "./RecipientBadge.vue?vue&type=script&lang=ts&"
export * from "./RecipientBadge.vue?vue&type=script&lang=ts&"
import style0 from "./RecipientBadge.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports