<template>
  <div class="compose-wrapper">
    <p
      v-if="loading"
      :key="`loadingDraft-${loading}`"
      class="panel-user-message"
    >
      <translate>Loading compose form…</translate>
    </p>
    <p v-else-if="error" class="panel-user-message">
      {{ errorText }}
    </p>

    <ComposeForm
      ref="composeForm"
      v-else
      :message="message"
      @saved="updateMessage"
      :focus-body="
        Boolean($route.query.inReplyTo || $route.query.inReplyToAll) &&
        Boolean(message.from.email)
      "
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
  import moment from 'moment';
  import escapeHtml from '@/lib/escapeHtml';
  import formatRecipient from '@/lib/formatRecipient';
  import ComposeForm from '@/components/ComposeForm/ComposeForm';
  import ErrorLoggerMixin from '@/mixins/error-logger-mixin';
  import { SET_ACTIVE_MESSAGE } from '@/store/mail/modules/messages';
  import getErrorCode from '@/api/get-error-code';
  import { IMAP_MESSAGE_DOES_NOT_EXIST } from '@/api/error-codes';
  import { MAIL_COMPOSE } from '@/router/named-routes';

  export default {
    components: {
      ComposeForm,
    },
    mixins: [ErrorLoggerMixin],
    data() {
      return {
        message: {
          in_reply_to: null,
          references: [],
          reply_to: [],
          to: [],
          cc: [],
          bcc: [],
          from: {},
          subject: '',
          body: '',
          encrypted: false,
          signed: false,
          attach_public_key: false,
          files: [],
          x_startmail_in_reply_to: null,
          x_startmail_forward_of: null,
          date: null,
        },
        loading: true,
        error: null,
      };
    },
    computed: {
      ...mapState({
        draft: (state) => state.drafts.draft,
      }),
      ...mapGetters([
        'activeMessage',
        'attachments',
        'selectedPrimaryEmailAddresses',
        'allPrimaryAddresses',
      ]),
      ...mapGetters('authentication', ['user']),
      ...mapGetters('aliases', ['senderAliases', 'findSenderAlias']),
      errorText() {
        switch (getErrorCode(this.error)) {
          case IMAP_MESSAGE_DOES_NOT_EXIST:
            return this.$gettext('Draft does not exist');
          default:
            return this.$gettext('Draft cannot be loaded');
        }
      },
    },
    async mounted() {
      if (this.user.preferences.show_welcome_compose_modal) {
        const { query } = this.$route;
        const isNewCompose = !query.inReplyTo && !query.forwardFrom;
        if (isNewCompose) {
          await this.$router.push({
            name: MAIL_COMPOSE,
            query: { modal: 'welcome-compose' },
          });
          await this.updatePreferences({
            update: { show_welcome_compose_modal: false },
          });
        }
      }
    },
    async created() {
      try {
        await this.loadAliases();
        await this.ensurePrefillDataIsLoaded();
        this.prefillMessage();
        this.$root.$emit('composeReady');
      } catch (error) {
        this.error = error;
        this.setToastMessage({ message: this.errorText });
        this.logException(error);
      }
      this.loading = false;
    },
    beforeRouteLeave(to, from, next) {
      // this "hack" is needed because there is no way to handle beforeRouteLeave in compose form
      if (this.$refs.composeForm) {
        this.$refs.composeForm.onLeaveRoute(next);
      } else {
        next(true);
      }
    },
    methods: {
      ...mapActions(['getDraft', 'loadDraftFromMessage', 'setToastMessage']),
      ...mapActions('authentication', ['updatePreferences']),
      ...mapActions('aliases', ['loadAliases']),
      ...mapMutations({
        setActiveMessage: SET_ACTIVE_MESSAGE,
      }),
      async ensurePrefillDataIsLoaded() {
        const inReplyToId = this.$route.query.inReplyTo;
        const inReplyToAllId = this.$route.query.inReplyToAll;
        const forwardFromId = this.$route.query.forwardFrom;
        const draftId = this.$route.query.draftId;
        const messageId = inReplyToId || inReplyToAllId || forwardFromId;

        if (messageId) {
          this.setActiveMessage({ id: messageId });
          await this.loadDraftFromMessage({
            id: messageId,
            includeAttachments: Boolean(forwardFromId),
          });
        } else if (draftId) {
          await this.getDraft({ id: draftId });
        }
      },
      prefillMessage() {
        const inReplyToId =
          this.$route.query.inReplyTo || this.$route.query.inReplyToAll;
        const forwardFromId = this.$route.query.forwardFrom;
        const draftId = this.$route.query.draftId;
        const mailToAddress = this.$route.query.mailTo;
        const subject = this.$route.query.subject;
        const sendTo = this.$route.query.sendTo;

        if (inReplyToId) {
          this.prefillReplyMessage(inReplyToId);
        } else if (forwardFromId) {
          this.prefillForwardMessage(forwardFromId);
        } else if (draftId) {
          this.prefillMessageFromDraft();
        } else if (mailToAddress) {
          this.message.to.push({
            contact: null,
            email: decodeURIComponent(mailToAddress),
            name: '',
          });
          if (subject) {
            this.message.subject = subject;
          }
          this.prefillNewMessage();
        } else if (sendTo === 'selectedContacts') {
          this.message.to = this.selectedPrimaryEmailAddresses;
          this.prefillNewMessage();
        } else if (sendTo === 'group') {
          this.message.to = this.allPrimaryAddresses;
          this.prefillNewMessage();
        } else {
          this.prefillNewMessage();
        }
      },
      findAlias(recipients) {
        return this.$store.state.aliases.aliases.find((alias) =>
          recipients.find(
            (recipient) =>
              alias.alias.toLowerCase() === recipient.email.toLowerCase()
          )
        );
      },
      isSender(alias) {
        return (
          this.message.from.email &&
          this.message.from.email.toLowerCase() === alias.toLowerCase()
        );
      },
      addSignature() {
        return this.user.preferences?.signature
          ? `<br><br>${this.user.preferences.signature}`
          : '';
      },
      prefillNewMessage() {
        const encrypt =
          this.$route.query.encrypt === 'true' ||
          this.user.preferences.pgp_auto_encrypt;

        const mailFrom = this.$route.query.mailFrom;
        if (mailFrom) {
          const sender = this.findSenderAlias([mailFrom]);
          this.message.from = sender
            ? { email: sender.alias, name: sender.displayName }
            : { email: '', name: '' };
        } else {
          this.message.from = {
            name: this.user.preferences.display_name,
            email: this.user.email,
          };
        }

        this.message.body = this.addSignature();
        // only toggle towards encryption, not away from it
        if (encrypt) {
          this.message.encrypted = true;
          this.$root.$emit('encryptionEnabledChanged', true);
        }
        this.message.signed = this.user.preferences.pgp_auto_sign;
        this.message.attach_public_key =
          this.user.preferences.pgp_auto_attach_public_key;
      },
      prefillSender() {
        // Set the sender (message.from) based on the sender and recipients of
        // the original message that is being replied to or forwarded. We leave
        // the sender blank (forcing the user to choose explicitly) when we
        // cannot determine the correct sender. This can happen for example when
        // an alias has expired or replying to BCC.
        if (this.$route.query.fromFCC === 'true') {
          const senderAlias = this.findSenderAlias([this.draft.from.email]);
          this.message.from = {
            name: senderAlias?.displayName ?? '',
            email: senderAlias?.alias ?? '',
          };
        } else {
          const senderAlias = this.findSenderAlias(
            [...this.draft.to, ...this.draft.cc, ...this.draft.bcc].map(
              (recipient) => recipient.email
            )
          );

          this.message.from = {
            name: senderAlias?.displayName ?? '',
            email: senderAlias?.alias ?? '',
          };
        }
      },
      prefillReplyMessage(inReplyToId) {
        const body =
          (this.activeMessage && this.activeMessage.body) ||
          this.draft.body ||
          '';

        this.message.in_reply_to = this.draft.message_id_header;
        this.message.references = [...this.draft.references];
        if (this.message.in_reply_to) {
          this.message.references.push(this.message.in_reply_to);
        }

        this.prefillSender();
        if (this.$route.query.fromFCC === 'true') {
          // If replying to a user's own copy of a message they sent, reply to original recipients.
          this.message.to = [...this.draft.to];

          // In case of reply all, also send to original CC and BCC.
          // Note that the BCC should be empty in all cases where we do not want to include it.
          if (this.$route.query.inReplyToAll) {
            this.message.cc = [...this.draft.cc];
            this.message.bcc = [...this.draft.bcc];
          }
        } else {
          // If replying a message the user received, reply to original sender.
          this.message.to = this.draft.reply_to.length
            ? this.draft.reply_to
            : [this.draft.from];

          // In case of reply all, also send to original recipients (excluding receivingAlias).
          // Note that the BCC should be empty in all cases where we do not want to include it.
          if (this.$route.query.inReplyToAll) {
            const uniqueReducer = (res, cur) => {
              const curInRes = res.find(
                (x) => x.email.toLowerCase() == cur.email.toLowerCase()
              );
              return curInRes ? res : [...res, cur];
            };
            const isNotSender = (recipient) => {
              return !this.isSender(recipient.email);
            }; // Matches against this.message.from
            this.message.to = [...this.message.to, ...this.draft.to]
              .reduce(uniqueReducer, [])
              .filter(isNotSender);
            this.message.cc = [...this.draft.cc].filter(isNotSender);
            this.message.bcc = [...this.draft.bcc].filter(isNotSender);
          }
        }

        const subject = this.cleanSubject(this.draft.subject);
        this.message.subject = `${this.$gettext('Re')}: ${subject}`;
        this.message.body = `${this.addSignature()}<br><br>
                                   <p data-squire-block>${this.replyHeaderText()}</p>
                                   <blockquote>${this.parseBody(
                                     body
                                   )}</blockquote>`;

        // enable pgp encryption if prefilled message was encrypted
        if (this.isSender(this.user.email)) {
          this.message.encrypted =
            this.$route.query.encrypt === 'true' ||
            this.user.preferences.pgp_auto_encrypt;
          // Enable user's pgp preferences.
          this.message.signed = this.user.preferences.pgp_auto_sign;
          this.message.attach_public_key =
            this.user.preferences.pgp_auto_attach_public_key;
        }

        this.message.x_startmail_in_reply_to = inReplyToId;
      },
      prefillForwardMessage(forwardFromId) {
        // References are only used on replies, never set them here. See: https://tools.ietf.org/html/rfc5322#section-3.6.4

        const body =
          (this.activeMessage && this.activeMessage.body) ||
          this.draft.body ||
          '';

        const subject = this.cleanSubject(this.draft.subject);
        this.message.subject = `${this.$gettext('Fwd')}: ${subject}`;
        this.message.body = `${this.addSignature()}<br><br>
                                   ${this.forwardHeaderText()}<br>
                                   <blockquote>${this.parseBody(
                                     body
                                   )}</blockquote>`;

        this.prefillSender();

        if (this.isSender(this.user.email)) {
          // enable pgp encryption if prefilled message was encrypted
          this.message.encrypted =
            this.$route.query.encrypt === 'true' ||
            this.user.preferences.pgp_auto_encrypt;

          // Enable user's pgp preferences.
          this.message.signed = this.user.preferences.pgp_auto_sign;
          this.message.attach_public_key =
            this.user.preferences.pgp_auto_attach_public_key;
        }

        this.message.files = [...this.message.files, ...this.draft.files];
        this.message.x_startmail_forward_of = forwardFromId;
      },
      prefillMessageFromDraft() {
        for (const key of Object.keys(this.message)) {
          this.message[key] = this.draft[key] ?? null;
        }

        // clear the sender address if it is not currently available
        if (
          this.message.from?.email &&
          !this.findSenderAlias([this.message.from.email])
        ) {
          this.message.from = { name: '', email: '' };
        }
      },
      updateMessage(update) {
        this.message = {
          ...this.message,
          ...update,
        };
      },
      parseBody(body) {
        const fragment = document.createRange().createContextualFragment(body);
        const serializer = new XMLSerializer();

        // remove sm-style blocks
        Array.from(fragment.querySelectorAll('sm-style')).forEach((el) =>
          el.parentNode.removeChild(el)
        );

        // Remove inlined attachments download links.
        fragment.querySelectorAll('.inlined-attachment-link').forEach((el) => {
          el.parentNode.removeChild(el);
        });

        return serializer.serializeToString(fragment);
      },
      cleanSubject(subject) {
        // Clean the subject of a message by removing the following prefixes:
        return (typeof subject === 'string' ? subject : '')
          .replace(/\bre?(-\d)?(\[\d\])?:\s*/gi, '') // R: Re: Re-1: Re[1]:
          .replace(/\ba(nt)?w(ort)?:\s*/gi, '') // aw: antwort:
          .replace(/\bfwd?:\s*/gi, '') // Fwd: Fw:
          .replace(/\b\[fwd?:.*\]\s*/gi, ''); // [Fwd: subject] [Fw: subject]
      },
      formatDate(dateStamp) {
        moment.locale(this.$language.current);
        return moment(dateStamp).format('LLLL');
      },
      replyHeaderText() {
        return this.draft
          ? this.$gettextInterpolate(
              this.$gettext('On %{ replyDate }, %{ replyFrom } wrote:'),
              {
                replyDate: this.formatDate(this.draft.date),
                replyFrom: formatRecipient(this.draft.from),
              }
            )
          : '';
      },
      forwardHeaderText() {
        const text = [
          `--- ${this.$gettext('Original message')} ---`,
          `${this.$gettext('Subject')}: ${this.draft.subject}`,
          `${this.$gettext('From')}: ${this.draft.from.email}`,
          `${this.$gettext('Date')}: ${this.formatDate(this.draft.date)}`,
          `${this.$gettext('To')}: ${this.draft.to
            .map((to) => formatRecipient(to))
            .join(', ')}`,
        ];

        if (this.draft.cc.length) {
          text.push(
            `${this.$gettext('Cc')}: ${this.draft.cc
              .map((cc) => formatRecipient(cc))
              .join(', ')}`
          );
        }

        return text
          .map(escapeHtml)
          .map((line) => `<div data-squire-block>${line}</div>`)
          .join('');
      },
    },
  };
</script>

<style>
  .compose-wrapper {
    display: flex;
    flex: auto;
  }
</style>
