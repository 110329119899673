import { parseOneAddress, ParsedMailbox } from 'email-addresses';

interface Recipient {
  contact: null;
  name: string | null;
  email: string;
}

export function isValid(address: string): boolean {
  return parseOneAddress(address) !== null;
}

export function toRecipient(address: string): Recipient {
  const parsedAddress = parseOneAddress(address);
  if (parsedAddress && parsedAddress.type === 'mailbox') {
    const parsedMailbox = parsedAddress as ParsedMailbox;
    const { name, address, parts } = parsedMailbox;

    const [firstToken] = parts.local.children;

    return {
      contact: null,
      name,
      email:
        firstToken.name === 'quoted-string'
          ? `"${parts.local.semantic}"@${parts.domain.semantic}`
          : address,
    };
  } else {
    return {
      contact: null,
      name: null,
      email: address,
    };
  }
}

export function toRecipientList(str: string): Recipient[] {
  const addresses = str.split(/[\0,;\n\t]+/);
  return addresses.map(toRecipient);
}
