var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"loading":_vm.loading,"include-in-render-tree":false},on:{"modalToggled":_vm.onModalToggled},scopedSlots:_vm._u([{key:"toggle",fn:function(ref){
var toggle = ref.toggle;
var beforeClose = ref.beforeClose;
var setLoading = ref.setLoading;
return [_vm._t("toggle",null,{"toggle":toggle,"beforeClose":beforeClose,"setLoading":setLoading})]}},{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.editModal ? _vm.editAliasText : _vm.createAliasText)+" "),_c('SupportCenterLink',{staticClass:"button button--icon-only modal__title-button",attrs:{"article":"360007297457"}},[_c('Icon',{attrs:{"symbol":"info"}})],1)]},proxy:true},{key:"content",fn:function(ref){
var toggle = ref.toggle;
return [_c('form',{ref:"form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm(toggle)}}},[(_vm.editModal)?_c('p',{staticClass:"email spacing-bottom",domProps:{"textContent":_vm._s(_vm.source.alias)}}):[_c('label',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"form-field__label",attrs:{"for":"localPart"}},[_vm._v(" Your alias ")]),_c('div',{staticClass:"your-alias"},[_c('InputField',{attrs:{"id":"localPart","label":"","required":"","validate":_vm.validate,"show-custom-validity":_vm.showCustomValidity,"validation-error-message":_vm.aliasValidationError},on:{"input":_vm.onCustomAliasInput},scopedSlots:_vm._u([(!_vm.hasMultipleDomains)?{key:"postfix",fn:function(){return [_c('span',{staticClass:"domain"},[_vm._v(" @"+_vm._s(_vm.domainOptions[0] && _vm.domainOptions[0].value)+" ")])]},proxy:true}:null],null,true),model:{value:(_vm.localPart),callback:function ($$v) {_vm.localPart=$$v},expression:"localPart"}}),(_vm.hasMultipleDomains)?_c('SelectInput',{attrs:{"label":"","id":"domain-selector-quick","options":_vm.domainOptions},on:{"change":_vm.onCustomAliasInput},model:{value:(_vm.domainPart),callback:function ($$v) {_vm.domainPart=$$v},expression:"domainPart"}}):_vm._e()],1),_c('p',{staticClass:"suggestion"},[_c('translate',[_vm._v("Suggestion:")]),_vm._v(" "+_vm._s(' ')+" "),_c('button',{directives:[{name:"test",rawName:"v-test:suggestion",arg:"suggestion"}],staticClass:"button button--small",attrs:{"type":"button","target":"_blank"},domProps:{"textContent":_vm._s(_vm.localPartSuggestion)},on:{"click":function($event){_vm.localPart = _vm.localPartSuggestion;
              _vm.onCustomAliasInput();}}})],1)],_c('InputField',{staticClass:"form-field",attrs:{"id":"displayName","label":_vm.displayNameText},scopedSlots:_vm._u([{key:"hint",fn:function(){return [_c('translate',[_vm._v(" If you would like to use a different sender name for this alias, you can change it here. ")])]},proxy:true}],null,true),model:{value:(_vm.displayName),callback:function ($$v) {_vm.displayName=$$v},expression:"displayName"}}),(_vm.type === 'group')?_c('MultiSelectInput',{staticClass:"form-field",attrs:{"id":"select-recipients","label":_vm.recipientsText,"required":"","options":_vm.recipientOptions,"placeholder":_vm.selectRecipients},model:{value:(_vm.recipients),callback:function ($$v) {_vm.recipients=$$v},expression:"recipients"}}):_vm._e(),_c('TextAreaField',{staticClass:"form-field",attrs:{"id":"note","label":_vm.noteText,"rows":3},scopedSlots:_vm._u([{key:"hint",fn:function(){return [_c('translate',[_vm._v(" Save information, notes and links related to this alias. This information is only visible to you. ")])]},proxy:true}],null,true),model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}}),(_vm.expiryFieldsVisible)?[_c('p',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"form-field form-field__label"},[_vm._v(" Automatically deactivate after (optional) ")]),_c('RadioInput',{directives:[{name:"test",rawName:"v-test:exp-button",arg:"exp-button"}],staticClass:"form-field",attrs:{"id":"expiry-time","options":_vm.expiryOptions},on:{"change":_vm.selectExpiryTime},model:{value:(_vm.selectedExpiryTime),callback:function ($$v) {_vm.selectedExpiryTime=$$v},expression:"selectedExpiryTime"}})]:_vm._e(),_c('ModalConfirmActions',{attrs:{"ok-text":_vm.okText,"loading":_vm.checkingAliasAvailability,"disabled":_vm.checkingAliasAvailability},on:{"cancelClicked":toggle}})],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }